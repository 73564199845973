import { mapPayerNameToCodeForDropdown } from "constants/practiceType";
import useDocumentTitle from "hooks/useDocumentTitle";
import { usePayerInformation } from "hooks/usePayerInformation";
import { useQuery } from "hooks/useQuery";
import { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";

import {
  Header,
  VisitForm,
  Submit,
  PatientInfoForm,
  OrderType,
  ServiceDetails,
  PayerForm,
  PatientBenefitsForm,
} from "./components";
import { TypesCode, orderTypes } from "./components/Header/constants";

export function CreateOrder() {
  const query = useQuery();
  useDocumentTitle("Create Order");
  const accountId = query.get("accountId");

  usePayerInformation();

  const methods = useForm({
    defaultValues: {
      email: null,
      amount: null,
      lastName: null,
      firstName: null,
      dateOfBirth: null,
      totalAmount: null,
      phoneNumber: null,
      accountNumber: null,
      dateOfService: null,
      timeOfService: null,
      serviceDetails: null,
      outOfPocketReason: null,
      preferredContactMethod: null,
      estimatedVisitCostForPatient: null,
      orderType: orderTypes[TypesCode.Bundled].value,
      accountId,
      payerName: null,
      placeOfService: null,
      locality: null,
      deductible: null,
      outOfPocketMax: null,
      flatCopay: null,
      coinsurancePercent: null,
      gfeQuoteId: null,
      practiceType: mapPayerNameToCodeForDropdown[0]?.value,
      orderId: null,
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FormProvider {...methods}>
      <Header />
      <OrderType />
      <PatientInfoForm />
      <PayerForm />
      <PatientBenefitsForm />
      <ServiceDetails />
      <VisitForm />
      <Submit />
    </FormProvider>
  );
}

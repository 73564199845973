import { Elements } from "@stripe/react-stripe-js";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { LoadingSpinner } from "components/LoadingSpinner";
import { ISPAConfig } from "config/api";
import {
  ED_ORDER_TYPE_EXPIRATION_PERIOD,
  orderTypeConstants,
} from "constants/order";
import { events } from "constants/tagManager";
import dayjs from "dayjs";
import { parseDate } from "helpers/date";
import { interpolateString } from "helpers/interpolate";
import { centsToDollars, parsePrice } from "helpers/price";
import { useAnalytics } from "hooks/useAnalytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import usePrice from "hooks/usePrice";
import { useQuery } from "hooks/useQuery";
import useRemoteConfig from "hooks/useRemoteConfig";
import { debounce, isEmpty } from "lodash";
import { disclaimerDefaultValues } from "pages/Customize/helper";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useGetCustomsByOrderTypeAndAccountIdQuery } from "store/queries/customize";
import {
  useCreateOrderPaymentIntentMutation,
  useGetPublicOrderQuery,
} from "store/queries/order";
import { onChangeAccountInfo } from "store/slices/order";
import { IOrder } from "types/Order/Order";
import {
  IProvider,
  SpecialtyList,
  SpecialtyListDict,
} from "types/Provider/Provider";

import { FinancingModal } from "../FinancingModal";
import { PaymentSection } from "../PaymentSection";
import DisclaimerDialog from "./components/DisclaimerDialog";
import PaymentPlan from "./components/PaymentPlan";
import { groupByCodeDescriptionAndCount } from "./helpers";
import {
  PageLayout,
  StyledButton,
  StyledDiscountBanner,
  StyledContainer,
  StyledServicesList,
  Spacer,
  PriceWrapper,
  Pill,
  HorizontalLine,
  StyledLinkButton,
} from "./styled";

export const getPublishableKey = () => {
  const config = window?.config as ISPAConfig;

  if (!config) {
    throw new Error("Config not found");
  }

  return config.stripe.publishableKey;
};

const DEFAULT_EXPIRATION_DAYS = 2;

export function Overview() {
  useDocumentTitle("Order Overview");
  const { config } = useRemoteConfig();
  const query = useQuery();
  const { id: externalId } = useParams();

  const internalUser = query.get("internalUser");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { getClearPriceByOrderType, getInsuranceAmounts, insuranceAmounts } =
    usePrice();
  const { pageViewEvents } = useAnalytics();

  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [disclaimerDialogVisible, setDisclaimerDialogVisible] = useState(false);

  const [clientSecret, setClientSecret] = useState("");

  const [financingModalOpen, setFinancingModalOpen] = useState(false);
  const [showPaymentSection, setShowPaymentSection] = useState(false);
  const [showClearEstimateDetailsSection, setShowClearEstimateDetailsSection] =
    useState(false);

  const { data, isLoading, isError } = useGetPublicOrderQuery(externalId || "");

  const [
    createOrderPaymentIntent,
    { isError: isStripeError, isLoading: isStripeLoading },
  ] = useCreateOrderPaymentIntentMutation();

  useEffect(() => {
    setStripePromise(loadStripe(getPublishableKey()));
  }, []);

  useEffect(() => {
    getInsuranceAmounts(data?.data.gfeQuote?.pricingBreakdown);
  }, [data?.data.gfeQuote?.pricingBreakdown]);

  const debouncedPageViewEvents = debounce((order, event: string) => {
    pageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.communicationMethod,
      },
      event
    );
  }, 300);

  useEffect(() => {
    if (isEmpty(data)) return;

    debouncedPageViewEvents(
      {
        accountName: data?.data.account.name,
        orderType: data?.data.orderType,
        communicationMethod: data?.data.patient.preferredContactMethod,
      },
      events.CHECKOUT
    );
  }, [data]);

  useEffect(() => {
    createOrderPaymentIntent(externalId)
      .unwrap()
      .then(({ data }: { data: any }) => setClientSecret(data.client_secret));
  }, []);

  const orderInfo = useMemo(() => {
    const accountInfo = data?.data.account;

    if (accountInfo) {
      dispatch(onChangeAccountInfo({ accountInfo }));
    }

    return data?.data || ({} as IOrder);
  }, [data]);

  const { data: contentData, isFetching: isContentFetching } =
    useGetCustomsByOrderTypeAndAccountIdQuery(
      { orderType: orderInfo.orderType, accountId: orderInfo.accountId },
      {
        skip: !orderInfo.orderType,
      }
    );

  const { overviewPage: content } = contentData?.data || {};

  if (!orderInfo || isError || isStripeError) {
    return <Navigate to="/not-found" />;
  }

  // We can't use the useLoading hook because the clientSecret is required to load the payment for now.
  if (isLoading || isStripeLoading || !clientSecret || isContentFetching) {
    return <LoadingSpinner />;
  }

  const prices = getClearPriceByOrderType({ order: orderInfo });
  const { percentageOff } = prices;

  const fullPrice = centsToDollars(prices.total);
  const clearPrice = centsToDollars(prices.clearPrice);
  const totalSaving = centsToDollars(prices.totalSaving);

  const physicianFee = centsToDollars(prices.physicianFee || 0);
  const facilityFee = centsToDollars(prices.facilityFee || 0);

  const financingButtonIsVisible =
    config?.financing_button_overview.asBoolean();

  const getExpirationDays = () => {
    if (isLoading) return DEFAULT_EXPIRATION_DAYS;

    if (orderInfo.orderType === orderTypeConstants.ED) {
      return ED_ORDER_TYPE_EXPIRATION_PERIOD;
    }

    return (orderInfo.account.providers[0] as unknown as IProvider)
      .expirationDays;
  };

  const expirationDays = getExpirationDays();

  const publicContact = () => {
    const currentProvider = orderInfo.account
      .providers[0] as unknown as IProvider;

    const isVisible =
      currentProvider.publicContactName &&
      currentProvider.publicContactRole &&
      currentProvider.publicContactPhone;

    return {
      isVisible,
      name: currentProvider.publicContactName,
      role: currentProvider.publicContactRole,
      phone: currentProvider.publicContactPhone,
    };
  };

  const fireClickOnFinancingEvent = () => {
    debouncedPageViewEvents(
      {
        accountName: data?.data.account.name,
        orderType: data?.data.orderType,
        communicationMethod: data?.data.patient.preferredContactMethod,
      },
      events.CLICK_FINANCING
    );
  };

  const procedures = groupByCodeDescriptionAndCount(
    orderInfo.serviceDetails.procedures
  );

  const parseText = (text: string) => {
    return interpolateString(text, {
      actualSavings: parsePrice(totalSaving),
    });
  };

  const showClearEstimateDetails = () => {
    setShowClearEstimateDetailsSection(!showClearEstimateDetailsSection);
  };

  const isGFE = orderInfo.orderType === orderTypeConstants.GFE;

  const {
    totalDeductibleInCents,
    totalCoinsuranceInCents,
    totalInsurancePayInCents,
    totalFlatCopayInCents,
    totalAllowedInsuranceAmount,
  } = insuranceAmounts;

  return (
    <Elements stripe={stripePromise} options={{ clientSecret }}>
      <FinancingModal
        {...{
          open: financingModalOpen,
          prices: { clearPrice, fullPrice },
          onClose: () => setFinancingModalOpen(false),
        }}
      />

      <PageLayout className="flex flex-column md:mt-3">
        {internalUser && (
          <StyledButton
            icon="pi pi-arrow-left"
            onClick={() => navigate(`/orders/${orderInfo.id}`)}
            className="p-button-text p-button-secondary p-0"
          >
            <p className="text-xs">Back to order</p>
          </StyledButton>
        )}

        {!showPaymentSection ? (
          <>
            <StyledDiscountBanner className=" text-center font-semibold border-round-md p-3">
              {parseText(content?.headline)}
            </StyledDiscountBanner>
            <StyledContainer>
              <div className="flex justify-content-between mb-3 mt-1 gap-2">
                <p className="black font-semibold gray uppercase">
                  Services Provided
                </p>
                <p className="gray font-medium text-right">
                  Visit Date {parseDate(orderInfo.dateOfService)}
                </p>
              </div>
              <StyledServicesList className="flex flex-column border-round-sm mb-3 gap-3">
                {procedures.map((currentProcedure: any) => (
                  <div key={currentProcedure.code}>
                    <p className=" font-medium mt-0">
                      {`${currentProcedure.code} - ${
                        currentProcedure.cptDescription
                      }${
                        currentProcedure.providers[0]?.quantity
                          ? `: Quantity ${currentProcedure.providers[0]?.quantity}`
                          : ""
                      }`}
                    </p>

                    {currentProcedure.providers.map(
                      (provider: {
                        name: string;
                        specialty: string;
                        quantity: number;
                      }) => (
                        <p className=" mt-0">
                          {`${provider.name}, ${
                            SpecialtyListDict[
                              provider.specialty as SpecialtyList
                            ]
                          }`}
                        </p>
                      )
                    )}
                  </div>
                ))}
              </StyledServicesList>
              <HorizontalLine />
              {isGFE && (
                <div className="flex mt-2" style={{ justifyContent: "end" }}>
                  <div
                    className="flex"
                    style={{ flexDirection: "column", alignItems: "end" }}
                  >
                    <Tag
                      className="purple"
                      style={{
                        background: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => showClearEstimateDetails()}
                    >
                      <div className="flex align-items-center gap-2">
                        {showClearEstimateDetailsSection ? (
                          <>
                            <span className="text-xs">Hide Details</span>
                            <i className="pi pi-chevron-up text-xs" />
                          </>
                        ) : (
                          <>
                            <span className="text-xs">
                              Why do I owe this amount?
                            </span>
                            <i className="pi pi-chevron-down text-xs" />
                          </>
                        )}
                      </div>
                    </Tag>
                    {showClearEstimateDetailsSection && (
                      <div
                        className="flex mt-2 p-4"
                        style={{
                          flexDirection: "column",
                          border: "1px",
                          borderRadius: "10px",
                          background: "rgba(71, 105, 234, 0.05)",
                        }}
                      >
                        <div className="flex gap-3 text-center mb-3">
                          {`After you meet your deductible, you will be responsible for ${orderInfo.gfeQuote?.coinsurancePercent}% of your medical bills (co-insurance) until you reach your annual out-of-pocket maximum. After that, insurance will cover 100% of the bills`}
                        </div>
                        <div
                          className="flex gap-3 mt-2 text-xs"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Total insurance allowable</div>
                          <div>
                            {parsePrice(
                              centsToDollars(totalAllowedInsuranceAmount)
                            )}
                          </div>
                        </div>
                        <div
                          className="flex gap-3 mt-2 text-xs"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Insurance will Pay</div>
                          <div>
                            {parsePrice(
                              centsToDollars(totalInsurancePayInCents)
                            )}
                          </div>
                        </div>
                        <div
                          className="flex gap-3 mt-2 text-xs"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Flat Copay Amount Applied</div>
                          <div>
                            {parsePrice(centsToDollars(totalFlatCopayInCents))}
                          </div>
                        </div>
                        <div
                          className="flex gap-3 mt-2 text-xs"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Deductible Amount Applied</div>
                          <div>
                            {parsePrice(centsToDollars(totalDeductibleInCents))}
                          </div>
                        </div>
                        <div
                          className="flex gap-3 mt-2 text-xs"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Co-insurance Amount Applied</div>
                          <div>
                            {parsePrice(
                              centsToDollars(totalCoinsuranceInCents)
                            )}
                          </div>
                        </div>
                        <StyledLinkButton
                          className="p-button-text p-button-primary text-xs p-0 mb-0 mt-2"
                          onClick={() => setDisclaimerDialogVisible(true)}
                        >
                          <p className="small-text">
                            {content.disclaimerTitleEnglish ||
                              disclaimerDefaultValues.disclaimerTitleEnglish}
                          </p>
                        </StyledLinkButton>
                        <DisclaimerDialog
                          visible={disclaimerDialogVisible}
                          onClose={() => setDisclaimerDialogVisible(false)}
                          englishTitle={
                            content.disclaimerTitleEnglish ||
                            disclaimerDefaultValues.disclaimerTitleEnglish
                          }
                          englishDescription={
                            content.disclaimerDescriptionEnglish ||
                            disclaimerDefaultValues.disclaimerDescriptionEnglish
                          }
                          spanishTitle={
                            content.disclaimerTitleSpanish ||
                            disclaimerDefaultValues.disclaimerTitleSpanish
                          }
                          spanishDescription={
                            content.disclaimerDescriptionSpanish ||
                            disclaimerDefaultValues.disclaimerDescriptionSpanish
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="flex mt-3">
                <div className="flex-1">
                  <p className="black font-semibold mb-1">
                    Estimated Payment Due
                  </p>
                  <p className="gray leading-5 max-w-10">
                    {parseText(content?.estimateDescription)}
                  </p>
                </div>
                <Spacer />
                <div className="flex-initial flex-column mb-2 w-100">
                  {content?.facilityEstimate && (
                    <div className="flex flex-column">
                      <p className="text-xs gray text-right">
                        {parseText(content?.facilityFeeDescription)}
                      </p>
                      <p className="text-lg font-bold text-right">
                        {parsePrice(facilityFee)}
                      </p>
                    </div>
                  )}

                  {content?.physicianEstimate && (
                    <div className="flex flex-column mt-2">
                      <p className="text-xs gray text-right">Physician fee</p>
                      <p className="text-lg font-bold text-right">
                        {parsePrice(physicianFee)}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <PriceWrapper className="flex flex-column justify-content-center align-items-center mt-3 mb-3">
                <div className="flex justify-content-between w-full">
                  <div className="text-left flex-1">
                    <h2 className="text-lg font-semibold mb-2">
                      {parseText(content?.clearPriceTitle) || "Pay Now Price"}
                    </h2>
                    <p className="text-xs gray text-left">
                      {parseText(content?.clearPriceDescription)}
                    </p>
                  </div>
                  <Spacer />
                  <div className="text-right">
                    <div className="flex align-items-end justify-content-end mt-1">
                      <p className="text-2xl purple font-bold text-right">
                        {parsePrice(clearPrice)}
                      </p>
                    </div>
                    {(content.discountPercentTotal === undefined ||
                      content.discountPercentTotal === true) && (
                      <>
                        <p className="text-xs green font-medium">{`${percentageOff}% off`}</p>
                        <p className="text-xs gray">
                          Total price:{" "}
                          <span style={{ textDecoration: "line-through" }}>
                            {parsePrice(fullPrice)}
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </PriceWrapper>
              {/* <PaymentPlan /> */}

              {content?.discountExpires && (
                <div className="flex justify-content-center">
                  <Pill className="mt-1 mb-3 border-round-md font-semibold py-1 px-2 text-center">
                    Discount expires on{" "}
                    {dayjs
                      .utc(orderInfo.dateOfService)
                      .add(expirationDays, "day")
                      .format("MMMM D, YYYY")}
                  </Pill>
                </div>
              )}
              {/* <Button
                className="w-full justify-content-center p-button-outlined mb-2"
                onClick={() => {}}
              >
                <span className="font-bold">Make Initial Payment</span>
              </Button> */}

              <Button
                className="w-full justify-content-center"
                onClick={() => setShowPaymentSection(true)}
              >
                <span className="font-bold">
                  {parseText(content?.purchaseButton)}
                </span>
              </Button>

              {financingButtonIsVisible && (
                <Button
                  className="w-full p-button-outlined mt-2 justify-content-center"
                  onClick={() => {
                    fireClickOnFinancingEvent();

                    if (content?.showFinancingModal) {
                      setFinancingModalOpen(true);
                    } else {
                      window.open(
                        "https://www.unitedcredit.com/applyforcredit?pid=0014X00002dq3S5",
                        "_blank"
                      );
                    }
                  }}
                >
                  {content?.financingButton}
                </Button>
              )}
            </StyledContainer>
            <div className="flex justify-content-center gap-4 mt-4">
              {publicContact().isVisible && (
                <div className="text-center gap-1 flex flex-column">
                  <p className=" gray font-bold">{publicContact().name}</p>
                  <p className=" gray"> {publicContact().role}</p>
                  <p className=" gray"> {publicContact().phone}</p>
                </div>
              )}

              <div className="text-center gap-1 flex flex-column">
                <p className=" gray font-bold">Brooke Leblanc</p>
                <p className=" gray">Clear Health Support</p>
                <p className=" gray">888-918-2522</p>
              </div>
            </div>
          </>
        ) : (
          <PaymentSection
            {...{
              expirationDays,
              order: orderInfo,
              prices: { clearPrice, totalSaving },
              onReturnClick: () => setShowPaymentSection(false),
            }}
          />
        )}
      </PageLayout>
    </Elements>
  );
}

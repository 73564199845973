import ChangeAccountDropdown from "components/ChangeAccountDropdown/ChangeAccountDropdown";
import PendingNotificationIcon from "components/PendingNotification/PendingNotification";
import { orderStatusConstants } from "constants/order";
import { UserRole } from "constants/userRole";
import { useUserWithAccount } from "context/UserAccountProvider";
import { sizer } from "layout/styles/styled/sizer";
import { OrdersTable } from "pages/Orders/components/OrdersTable";
import { Providers } from "pages/Providers";
import { Button } from "primereact/button";
import { TabView, TabPanel } from "primereact/tabview";
import { Tooltip } from "primereact/tooltip";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetOrdersQuery } from "store/queries/order";
import styled from "styled-components";
import { IAccount } from "types/Account/Account";

const StyledContainer = styled.div`
  margin-bottom: ${sizer(8)};
`;

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

export default function ViewHospitals() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentPendingAmount, setCurrentPendingAmount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { accounts, userInfo } = useUserWithAccount();
  const { data: orders } = useGetOrdersQuery(id);

  const currentAccount: IAccount | undefined = accounts?.data.find(
    (aAccount) => aAccount.id === id
  );
  const currentAccountPendingOrders = orders?.data.filter(
    (order) => order.status === orderStatusConstants.DRAFT
  );

  useEffect(() => {
    if (currentAccountPendingOrders) {
      setCurrentPendingAmount(currentAccountPendingOrders.length);
    }
  }, [currentAccountPendingOrders]);

  const filteredAccounts = useMemo(() => {
    if (userInfo?.scope === UserRole.SUPER_ADMIN) {
      return accounts?.data || [];
    }
    return (
      accounts?.data.filter((aAccount) =>
        userInfo?.accountIds.includes(aAccount.id)
      ) || []
    );
  }, [accounts]);

  const handleAccountChange = (newlySelectedAccount: IAccount) => {
    const selectedAccountPendingOrders = newlySelectedAccount.orders?.filter(
      (order) => order.status === orderStatusConstants.DRAFT
    );
    setCurrentPendingAmount(selectedAccountPendingOrders?.length || 0);
  };

  return (
    <>
      {userInfo?.scope === UserRole.SUPER_ADMIN ? (
        <StyledButton
          icon="pi pi-arrow-left"
          onClick={() => navigate("/hospitals")}
          className="p-button-text p-button-secondary p-0"
        >
          <p className="small-text">Return to Hospitals</p>
        </StyledButton>
      ) : null}
      <StyledContainer className="flex flex-wrap gap-6 align-items-center">
        <h2>{currentAccount?.name}</h2>
        <div className="flex gap-4 align-items-center">
          {filteredAccounts.length > 1 ? (
            <ChangeAccountDropdown
              defaultSelected={currentAccount?.name || null}
              accounts={filteredAccounts}
              onAccountChange={(newlySelectedAccount) =>
                handleAccountChange(newlySelectedAccount)
              }
            />
          ) : null}
          {currentPendingAmount ? (
            <>
              <Tooltip
                style={{
                  fontSize: "14px",
                }}
                target=".pending-notification"
              />
              <PendingNotificationIcon
                count={currentPendingAmount || 0}
                className="pending-notification cursor-pointer"
                tooltipText="Orders Pending Review"
                tooltipPosition="right"
              />
            </>
          ) : null}
        </div>
      </StyledContainer>

      <TabView
        activeIndex={Number(searchParams.get("tab"))}
        className="w-full"
        onTabChange={(e) => setSearchParams({ tab: e.index.toString() })}
      >
        <TabPanel header="Orders">
          <OrdersTable accountId={id} />
        </TabPanel>

        <TabPanel header="Providers">
          <Providers />
        </TabPanel>
      </TabView>
    </>
  );
}

export const validationConstants = {
  PATIENT_INFO_FORM: {
    EMAIL: "Email is required.",
    PHONE: "Phone is required.",
    FIRST_NAME: "First name is required.",
    LAST_NAME: "Last name is required.",
    DOB: "Date of Birth is required.",
    CONTACT_METHOD: "Contact method is required.",
    MEDICAL_RECORD: "Medical Record Number is required.",
  },
  VISIT_INFO_FORM: {
    DATE_OF_SERVICE: "Date of service is required.",
    VISIT_ID: "Account/Visit Id is required.",
    ESTIMATED_VISIT_CODE_FOR_PATIENT: "Estimated cost is required.",
    OUT_OF_POCKET_REASON: "Reason is required.",
  },
  CUSTOMIZE_FORM: {
    HEADLINE: "Headline is required.",
    DESCRIPTION: "Description is required.",
    ESTIMATE_DESCRIPTION: "Estimate description is required.",
    FACILITY_DESCRIPTION: "Facility description is required.",
    VERIFY_BUTTON: "Verify button description is required.",
    FINANCING_BUTTON: "Financing button description is required.",
    PURCHASE_BUTTON: "Purchase button description is required.",
    PAYMENT_PLAN_BUTTON: "Payment plan button description is required.",
    CLEAR_PRICE_DESCRIPTION: "Clear Price description description is required.",
    CLEAR_PRICE_TITLE: "Clear Price title is required.",
    GFE_DISCLAIMER_TITLE: "Disclaimer title is required.",
    GFE_DISCLAIMER_DESCRIPTION: "Disclaimer description is required.",
  },
  SERVICE_DETAILS: {
    FACILITY_ESTIMATE: "Facility Patient Estimate required",
    PATIENT_ESTIMATE: "Estimated cost is required",
    GFE_CLEAR_PRICE: "Clear Price for Patient is required",
  },
};

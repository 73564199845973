import { Chip, ChipVariants } from "components/Chip";
import { parseDate } from "helpers/date";
import { parsePrice, centsToDollars } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import { IPayment } from "types/Payment";

const StyledCard = styled(Card)`
  margin-bottom: ${sizer(2)};
  flex-grow: 1;
`;

function PaymentDetails({ payments }: { payments?: IPayment[] }) {
  const paidPayment: IPayment | undefined = payments?.find(
    (aPayment) => aPayment.status === "Paid"
  );
  const refundedPayment: IPayment | undefined = payments?.find(
    (aPayment) => aPayment.status === "Refunded"
  );
  return (
    <StyledCard
      title="Payment details"
      header={
        refundedPayment && (
          <div className="flex justify-content-end pt-2 pr-3">
            <Chip variant={ChipVariants.WARNING}>Refunded</Chip>
          </div>
        )
      }
    >
      <div className="formgrid grid w-100">
        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentDate">Payment Date</Label>

          <InputText
            disabled
            id="paymentDate"
            aria-labelledby="paymentDate"
            value={
              paidPayment?.createdAt ? parseDate(paidPayment.createdAt) : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentAmount">Payment Amount</Label>

          <InputText
            disabled
            id="paymentAmount"
            aria-labelledby="paymentAmount"
            value={
              paidPayment?.amountInCents
                ? parsePrice(centsToDollars(paidPayment.amountInCents))
                : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="depositDate">Deposit Date</Label>

          <InputText
            disabled
            id="depositDate"
            aria-labelledby="depositDate"
            value={
              paidPayment?.stripePayout?.payoutArrivalDate
                ? parseDate(paidPayment.stripePayout.payoutArrivalDate)
                : "-"
            }
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentMethod">Payment Method</Label>

          <InputText
            disabled
            id="paymentMethod"
            value={paidPayment?.type || "-"}
            aria-labelledby="paymentMethod"
          />
        </div>

        <div className="field flex flex-column col-12 md:col-4">
          <Label htmlFor="paymentStatus">Status</Label>

          <InputText
            disabled
            id="paymentStatus"
            value={paidPayment?.status || "-"}
            aria-labelledby="paymentStatus"
          />
        </div>
      </div>
    </StyledCard>
  );
}

export default PaymentDetails;

import { api } from ".";

const feeSchedule = api.injectEndpoints({
  endpoints: (builder) => ({
    createFeeSchedule: builder.mutation<{ providerId: string; file: any }, any>(
      {
        query: ({ providerId, file, payerId }) => {
          const formData = new FormData();

          formData.append("file", file);

          return {
            url: `provider_procedures/import/?providerId=${providerId}&payerId=${payerId}`,
            method: "POST",
            body: formData,
            formData: true,
          };
        },
      }
    ),
  }),
});

export const { useCreateFeeScheduleMutation } = feeSchedule;

import { Chip } from "components/Chip";
import {
  orderStatusChipVariantsMapping,
  orderStatusConstants,
  orderStatusMapping,
  orderTypeMapping,
} from "constants/order";
import { events } from "constants/tagManager";
import { UserRole } from "constants/userRole";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import {
  sumOfFacilityProcedures,
  sumOfProvidersProcedures,
} from "helpers/price";
import { useAnalytics } from "hooks/useAnalytics";
import { useOrder } from "hooks/useOrder";
import usePrice from "hooks/usePrice";
import { sizer } from "layout/styles/styled/sizer";
import { Steps } from "pages/OrderOverview/utils/constants";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import {
  useCancelSingleOrderMutation,
  useCreatePaymentMutation,
  useUpdateOrderMutation,
} from "store/queries/order";
import { useMarkPaymentReadyForTransfersMutation } from "store/queries/payment";
import { onPublicTokenChange } from "store/slices/auth";
import {
  onChangeViewOrderViewReceipt,
  onOrderOverviewStepChange,
} from "store/slices/order";
import { userSelector } from "store/slices/user";
import styled from "styled-components";
import { IOrder } from "types/Order/Order";
import { OrderStatus } from "types/Order/OrderStatus";

import { ClearOrderProDownloadButton } from "./components/Download";
import { MarkAsPaidCashDialog } from "./components/MarkAsPaidCashDialog/MarkAsPaidCashDialog";
import { MoneyTransferDialog } from "./components/MoneyTransferDialog";

export const StyledButton = styled(Button)`
  gap: ${sizer(2)};
  margin-bottom: ${sizer(4)};

  span:first-child {
    font-size: ${sizer(3)};
  }
`;

const HeadingContainer = styled.div`
  gap: ${sizer(4)};
  margin-bottom: ${sizer(2)};
`;

const StyledSubtitle = styled.h2`
  color: var(--bluegray-500);
  font-size: var(--fontsize-contents);
  font-weight: var(--font-weight-medium);

  line-height: ${sizer(5)};
`;

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${sizer(2)};
  justify-content: space-between;
`;

const StyledH2 = styled.h2`
  margin-bottom: ${sizer(8)};
`;

type PaymentType = "Financing" | "Cash";

export function Header({
  order,
  onUpdateOrder,
}: {
  order: IOrder;
  onUpdateOrder: () => void;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pageViewEvents } = useAnalytics();
  const { scope } = useSelector(userSelector);
  const {
    isGFE,
    mainFacility,
    isStatusSentToPatient,
    isBariatricsNotReadyForTransfer,
    isStatusExpired,
    payment,
    isDefaultOrderType,
  } = useOrder(order);

  const { getClearPriceByOrderType, getInsuranceAmounts, insuranceAmounts } =
    usePrice();

  const { current: toastElement } = useContext(ToastContext);

  const [login] = useLoginWithOrderIdAndDOBMutation();
  const [createPayment] = useCreatePaymentMutation();
  const [cancelSingleOrder] = useCancelSingleOrderMutation();
  const [updateOrder] = useUpdateOrderMutation();
  const [markPaymentReadyForTransfers] =
    useMarkPaymentReadyForTransfersMutation();

  const [showDialog, setShowDialog] = useState(false);
  const [transfers, setTransfers] = useState([
    {
      selectedPartyToTransferFrom: null,
      selectedPartyToTransferTo: null,
      selectedAmountToTransfer: 0,
    },
  ]);
  const [showMoneyTransferDialog, setShowMoneyTransferDialog] = useState(false);
  const [providerToCharge, setProviderToCharge] = useState<string>();
  const showPartialPayment = useMemo(() => {
    // show only for those orders that are paid and do not have any cash payment
    return (
      order.status === orderStatusConstants.PAID &&
      !order.payments.some((payment) => payment.type === "Cash")
    );
  }, [order]);

  useEffect(() => {
    getInsuranceAmounts(order.gfeQuote?.pricingBreakdown);
  }, [order.gfeQuote?.pricingBreakdown]);

  const { clearPrice, percentageOff } = getClearPriceByOrderType({
    order,
  });

  const getAmountFinancingOrCash = (paymentType: PaymentType) => {
    let amountInCents: number;

    if (paymentType === "Financing") {
      amountInCents = order.amountInCents;
    } else {
      amountInCents = clearPrice;
    }

    return amountInCents;
  };

  const markViaFinancingOrCash = (paymentType: PaymentType) => {
    const amountInCents: number = getAmountFinancingOrCash(paymentType);

    login({
      orderId: order.externalId || "",
      dateOfBirth: new Date(order.patient.dateOfBirth).toISOString(),
    })
      .unwrap()
      .then(({ token }: { token: string }) => {
        dispatch(onPublicTokenChange({ token }));

        createPayment({
          orderId: order.id,
          amountInCents,
          paymentType,
          providerId: providerToCharge,
        })
          .unwrap()
          .then(async () => {
            onUpdateOrder();
            pageViewEvents(
              {
                accountName: order.account.name,
                orderType: order.orderType,
                communicationMethod: order.patient.preferredContactMethod,
              },
              events.CLICK_FINANCING
            );

            toastElement?.show({
              summary: "Success!",
              severity: "success",
              detail: "Your order has been updated.",
            });
          })
          .catch(() => {
            toastElement?.show({
              severity: "error",
              detail: "Try again later.",
              summary: "Something went wrong.",
            });
          });
      })
      .catch(() => {
        toastElement?.show({
          severity: "error",
          detail: "Try again later.",
          summary: "Something went wrong.",
        });
      });
  };

  const acceptPayment = () => {
    login({
      orderId: order.externalId || "",
      dateOfBirth: new Date(order.patient.dateOfBirth).toISOString(),
    })
      .unwrap()
      .then(({ token }: { token: string }) => {
        dispatch(onPublicTokenChange({ token }));
        dispatch(
          onOrderOverviewStepChange({
            step: Steps.ORDER_OVERVIEW,
          })
        );
        pageViewEvents(
          {
            accountName: order.account.name,
            orderType: order.orderType,
            communicationMethod: order.patient.preferredContactMethod,
          },
          events.CLICK_ACCEPT_PAYMENT
        );
        navigate(`/order/${order.externalId}?internalUser=true`);
      })
      .catch(() => {
        const detail =
          "Authentication failed. Please check your date of birth.";

        toastElement?.show({
          detail,
          summary: "Error",
          severity: "error",
        });
      });
  };

  const cancelOrder = () => {
    cancelSingleOrder(order.id)
      .unwrap()
      .then(() => {
        onUpdateOrder();
        toastElement?.show({
          severity: "success",
          summary: "Order canceled",
          detail: "Order canceled successfully.",
        });
      })
      .catch((error) =>
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error}`,
        })
      );
  };

  const changeOrderStatus = (newStatus: OrderStatus) => {
    updateOrder({
      ...order,
      id: order.id,
      status: newStatus,
    })
      .then(() => {
        onUpdateOrder();
        toastElement?.show({
          severity: "success",
          summary: "Order status changed",
          detail: "Order status changed successfully",
        });
      })
      .catch((error) => {
        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: `Message: ${error}`,
        });
      });
  };

  const handleOnClickToMarkCashPaid = () => {
    setShowDialog(true);
  };

  const handleOnClickToMarkFinancingPaid = () => {
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to mark as paid via ${"Financing".toLowerCase()}?`,
      accept: () => markViaFinancingOrCash("Financing"),
    });
  };

  const handleOnClickToMarkReadyForTransfer = () => {
    if (!payment) return;
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to mark the payment on this order as ready for Stripe transfers?`,
      accept: () => {
        markPaymentReadyForTransfers(payment.id)
          .unwrap()
          .then(() => {
            onUpdateOrder();
            toastElement?.show({
              severity: "success",
              summary: "Ready for transfers",
              detail: "Stripe transfers will be initiated shortly",
            });
          })
          .catch((error) =>
            toastElement?.show({
              severity: "error",
              summary: "Error",
              detail: `Message: ${error}`,
            })
          );
      },
    });
  };

  const handleOnClickToCancelOrder = () => {
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to cancel this order?`,
      accept: cancelOrder,
    });
  };

  const handleOnClickPartialRefund = () => {
    setShowMoneyTransferDialog(true);
  };

  const handleOnClickToChangeOrderStatus = (value: OrderStatus) => {
    confirmDialog({
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      message: `Are you sure you want to change this order status?`,
      accept: () => changeOrderStatus(value),
    });
  };

  const handleGoToOrdersPage = () => {
    navigate(`/hospitals/view/${order.account.id}${location.search}`);
  };

  return (
    <Container>
      <MarkAsPaidCashDialog
        paymentType="Cash"
        markViaFinancingOrCash={markViaFinancingOrCash}
        order={order}
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        providerToCharge={providerToCharge}
        setProviderToCharge={setProviderToCharge}
      />
      <MoneyTransferDialog
        showDialog={showMoneyTransferDialog}
        order={order}
        setShowDialog={setShowMoneyTransferDialog}
        transfers={transfers}
        setTransfers={setTransfers}
        onUpdateOrder={onUpdateOrder}
      />
      <div>
        <StyledButton
          data-testid="orders-dashboard_OrderHeader"
          icon="pi pi-arrow-left"
          onClick={handleGoToOrdersPage}
          className="p-button-text p-button-secondary p-0"
        >
          <p className="small-text">Orders Dashboard</p>
        </StyledButton>
        <StyledH2>{order?.account.name}</StyledH2>

        <HeadingContainer className="flex align-items-center">
          <h1>Order Details</h1>
          {scope === UserRole.SUPER_ADMIN && isStatusExpired ? (
            <Dropdown
              data-testid="change-order-status-dropdown_OrderHeader"
              className="p-dropdown-chip"
              value={orderStatusMapping[order?.status]}
              onChange={(e) => {
                handleOnClickToChangeOrderStatus(e.value.code as OrderStatus);
              }}
              options={[
                {
                  name: "Change status to Sent",
                  code: orderStatusConstants.SENT_TO_PATIENT,
                },
              ]}
              optionLabel="name"
              placeholder="Expired"
            />
          ) : (
            <Chip variant={orderStatusChipVariantsMapping[order?.status]}>
              {orderStatusMapping[order?.status]}
            </Chip>
          )}
        </HeadingContainer>

        <StyledSubtitle>
          {`${orderTypeMapping[order?.orderType]} • Created on ${parseDate(
            order?.createdAt || ""
          )}`}
        </StyledSubtitle>
      </div>

      <div className="flex gap-3 flex-wrap justify-end">
        {order.status === orderStatusConstants.PAID && (
          <StyledButton
            data-testid="payment-receipt_OrderHeader"
            icon="pi pi-file"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={() => dispatch(onChangeViewOrderViewReceipt(true))}
          >
            <p className="small-text">Payment receipt</p>
          </StyledButton>
        )}
        {isStatusSentToPatient && (
          <>
            {scope === UserRole.SUPER_ADMIN && (
              <>
                <StyledButton
                  data-testid="mark-paid-financing_OrderHeader"
                  icon="pi pi-check-circle"
                  className="p-button-text p-button-primary p-0 mb-0"
                  onClick={() => handleOnClickToMarkFinancingPaid()}
                >
                  <p className="small-text">Mark paid via financing</p>
                </StyledButton>
                <StyledButton
                  data-testid="mark-paid-cash_OrderHeader"
                  icon="pi pi-check-circle"
                  className="p-button-text p-button-primary p-0 mb-0"
                  onClick={() => handleOnClickToMarkCashPaid()}
                >
                  <p className="small-text">Mark paid via cash</p>
                </StyledButton>
              </>
            )}
            <StyledButton
              data-testid="accept-payment_OrderHeader"
              icon="pi pi-check-circle"
              className="p-button-text p-button-primary p-0 mb-0"
              onClick={acceptPayment}
            >
              <p className="small-text">Accept payment</p>
            </StyledButton>
          </>
        )}
        {isBariatricsNotReadyForTransfer && scope === UserRole.SUPER_ADMIN && (
          <StyledButton
            data-testid="ready-for-transfer_OrderHeader"
            icon="pi pi-check-circle"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={() => handleOnClickToMarkReadyForTransfer()}
          >
            <p className="small-text">Ready for Transfer</p>
          </StyledButton>
        )}
        {scope === UserRole.SUPER_ADMIN && (
          <StyledButton
            data-testid="cancel-order_OrderHeader"
            icon="pi pi-file"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={handleOnClickToCancelOrder}
          >
            <p className="small-text">Cancel Order</p>
          </StyledButton>
        )}
        {showPartialPayment && (
          <StyledButton
            data-testid="money-transfer_OrderHeader"
            icon="pi pi-file"
            className="p-button-text p-button-primary p-0 mb-0"
            onClick={handleOnClickPartialRefund}
          >
            <p className="small-text">Partial Refund</p>
          </StyledButton>
        )}

        {(isGFE || isDefaultOrderType) && (
          <ClearOrderProDownloadButton
            data={{
              facilityLogo: order.account.accountLogoUrl || "",
              name: `${order.patient.firstName} ${order.patient.lastName}`,
              fullAddress: `${mainFacility.streetAddress}, ${mainFacility.city}, ${mainFacility.state}`,
              phoneNumber: mainFacility.phoneNumber,
              dateOfBirth: parseDate(order.patient.dateOfBirth),
              payerName: (order.serviceDetails as any)?.payerName,
              serviceDate: order.dateOfService,
              serviceDetails: order.serviceDetails,
              facilityName: mainFacility.name,
              gfeQuote: order.gfeQuote,
              clearDiscount: percentageOff,
              clearPriceToPay: clearPrice,
              facilityFee: sumOfFacilityProcedures(
                order.serviceDetails.procedures
              ),
              providerFee: sumOfProvidersProcedures(
                order.serviceDetails.procedures
              ),
              isGFE,
              insuranceAmounts,
            }}
          />
        )}
      </div>
    </Container>
  );
}

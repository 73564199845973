import { Document, Page, Text, Image, View } from "@react-pdf/renderer";
import { parseDate } from "helpers/date";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { Pricing } from "types/CreateOrder";
import {
  IDefaultProcedure,
  IPatientResponsibilityProcedure,
} from "types/Order/Order";

import { cellphoneImage } from "./assets/cellphoneImage";
import { detailBgImage } from "./assets/detailBgImage";
import { ChargeDescriptionClearEstimate } from "./partials/ChargeDescription";
import { EnglishInformation } from "./partials/EnglishInformation";
import { Footer } from "./partials/Footer";
import { Header } from "./partials/Header";
import { SpanishInformation } from "./partials/SpanishInformation";
import { styles } from "./styles";

export type Props = {
  name: string;
  facilityLogo: string;
  fullAddress?: string;
  phoneNumber?: string;
  serviceDetails: any;
  dateOfBirth: string;
  payerName: string;
  serviceDate: string;
  facilityName?: string;
  gfeQuote: Pricing | undefined;
  clearPriceToPay: number;
  isGFE: boolean;
  insuranceAmounts: any;
  providerFee: number | undefined;
  facilityFee: number;
  clearDiscount: number;
};

export function ClearOrderDocument({
  name,
  facilityLogo,
  fullAddress,
  phoneNumber,
  serviceDetails,
  dateOfBirth,
  payerName,
  serviceDate,
  facilityName,
  gfeQuote,
  clearPriceToPay,
  isGFE,
  insuranceAmounts,
  providerFee,
  facilityFee,
  clearDiscount,
}: Props) {
  type UniqueProcedureList =
    | IDefaultProcedure
    | IPatientResponsibilityProcedure;

  const uniqueProcedureList = useMemo(() => {
    if (isEmpty(serviceDetails)) return [];

    const { procedures } = serviceDetails;

    const uniqueArray = [] as unknown as UniqueProcedureList[];
    const seenValues = new Set();

    procedures?.forEach((item: any) => {
      const valueToCheck = item.code;

      if (!seenValues.has(valueToCheck)) {
        seenValues.add(valueToCheck);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  }, [serviceDetails]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Header
          facilityLogo={facilityLogo}
          fullAddress={fullAddress}
          phoneNumber={phoneNumber}
        />
        {/* CONTENT */}
        <View style={styles.content}>
          <View style={{ ...styles.section, marginTop: 12 }}>
            <Text style={styles.title}>Good Faith Estimate</Text>
          </View>
          <View style={{ ...styles.section, marginTop: 0 }}>
            <Text style={{ ...styles.bodyText, lineHeight: 1.2 }}>
              This estimate uses procedure information provided by your
              physician as well as the benefits available from your insurance
              company (if applicable). This is not a guarantee of the final
              amount due. Hospital care is tailored to the specific needs of
              each patient. Therefore, the final amount owed may vary based on
              circumstances involved in your actual services.
            </Text>
            <Text style={{ ...styles.bodyText, lineHeight: 1.2 }}>
              Esta estimación utiliza la información del procedimiento
              proporcionada por su médico, así como los beneficios disponibles
              de su compañía de seguros (si corresponde). Esto no es una
              garantía del monto final adeudado. La atención hospitalaria se
              adapta a las necesidades específicas de cada paciente. Por lo
              tanto, el monto final adeudado puede variar según las
              circunstancias involucradas en sus servicios reales.
            </Text>
          </View>
          <View style={{ ...styles.section, marginTop: 0, marginBottom: 0 }}>
            <View
              style={{
                display: "flex",
                marginBottom: "8px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ ...styles.listTitle, width: 100 }}>Patient</Text>
              <Text
                style={styles.smallText}
              >{`: ${name}, Date of Birth: ${parseDate(dateOfBirth)}`}</Text>
            </View>
            {!isEmpty(payerName) && (
              <View
                style={{
                  display: "flex",
                  marginBottom: "8px",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text style={{ ...styles.listTitle, width: 100 }}>Payer</Text>
                <Text style={styles.smallText}>{`: ${payerName}`}</Text>
              </View>
            )}
            <View
              style={{
                display: "flex",
                marginBottom: "8px",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={{ ...styles.listTitle, width: 100 }}>
                Service Date
              </Text>
              <Text style={styles.smallText}>{`: ${parseDate(
                serviceDate
              )}`}</Text>
            </View>
          </View>
          <View style={{ ...styles.section, marginBottom: 4, marginTop: 0 }}>
            <View style={styles.list}>
              <Text style={{ ...styles.listTitle, marginBottom: 4 }}>
                Services Provided
              </Text>

              {uniqueProcedureList.map((currentProcedure) => (
                <Text style={styles.itemList} key={currentProcedure.code}>
                  {currentProcedure.cptDescription} - {currentProcedure.code}
                </Text>
              ))}
            </View>
          </View>
          <ChargeDescriptionClearEstimate
            gfeQuote={gfeQuote}
            clearPriceToPay={clearPriceToPay}
            isGFE={isGFE}
            insuranceAmounts={insuranceAmounts}
            facilityFee={facilityFee}
            providerFee={providerFee}
            clearDiscount={clearDiscount.toString()}
          />
          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  zIndex: -1,
                  width: "100%",
                  position: "absolute",
                  marginTop: 60,
                }}
              >
                <Image src={detailBgImage} />
              </View>
              <View
                style={{
                  flex: 2,
                  textAlign: "left",
                  marginTop: 50,
                  paddingTop: "20px",
                  paddingBottom: "30px",
                  paddingLeft: "60px",
                  paddingRight: "60px",
                  marginRight: 50,
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    ...styles.bodyText,
                    marginBottom: 0,
                    lineHeight: "1.6px",
                  }}
                >
                  To qualify for the{" "}
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    Prompt Pay Discount
                  </Text>{" "}
                  {`you need to pay before ${parseDate(
                    serviceDate,
                    true
                  )} using the link provided to you via text through our Clear Health Partner.`}
                </Text>
                <Text style={{ ...styles.bodyText, lineHeight: "1.6px" }}>
                  For more information, please contact Clear Health at
                  1-888-918-2522.
                </Text>
                <Text style={styles.bodyText}>
                  {`For questions regarding your estimate please call 1-${phoneNumber}`}
                </Text>
              </View>
              <View
                style={{ flex: 1, alignItems: "flex-end", paddingRight: 20 }}
              >
                <Image
                  style={{ height: 270, width: 200 }}
                  src={cellphoneImage}
                />
              </View>
            </View>
          </View>
        </View>
        {/* END OF CONTENT */}
        <Footer facilityName={facilityName} />
      </Page>
      <EnglishInformation
        facilityLogo={facilityLogo}
        fullAddress={fullAddress || ""}
        phoneNumber={phoneNumber || ""}
        facilityName={facilityName || ""}
      />
      <SpanishInformation
        facilityLogo={facilityLogo}
        fullAddress={fullAddress || ""}
        phoneNumber={phoneNumber || ""}
        facilityName={facilityName || ""}
      />
    </Document>
  );
}

import dayjs from "dayjs";
import { usePayerInformation } from "hooks/usePayerInformation";
import { isEmpty } from "lodash";
import { Button } from "primereact/button";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useCreateEligibilityMutation } from "store/queries/eligibility";
import {
  IEligibilityMember,
  IEligibilityProvider,
  IProvider,
} from "types/Provider/Provider";

export function EligibilityButton({
  mainProvider,
  errorMessageSetter,
}: {
  mainProvider: IProvider;
  errorMessageSetter: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { getValues, setValue } = useFormContext();
  const [runningEligibility, setRunningEligibility] = useState(false);
  const { payerNameCodes } = usePayerInformation();

  const [createEligibility] = useCreateEligibilityMutation();

  const dateOfServiceStart = dayjs().add(2, "days").format("MM/DD/YYYY");
  const dateOfServiceEnd = dayjs().add(2, "days").format("MM/DD/YYYY");

  const mapValuesToEligibility = async () => {
    errorMessageSetter("");
    const { name: providerName, npi } = mainProvider;
    const {
      firstName: memberFirstName,
      lastName: memberLastName,
      dateOfBirth: memberDOB,
      memberId,
      payerName,
      isSubscriberPatient,
      practiceType,
    } = getValues();

    const provider: IEligibilityProvider = {
      firstName: providerName,
      middleName: providerName,
      lastName: providerName,
      npi,
    };

    const member: IEligibilityMember = {
      firstName: memberFirstName,
      lastName: memberLastName,
      dob: memberDOB,
      memberID: memberId,
    };

    const payerCode =
      payerNameCodes.find(
        (payer: { name: string; code: string[] }) => payer.name === payerName
      )?.code[0] || "";

    const eligibility = {
      payerCode: payerCode || "",
      payerName,
      provider,
      member,
      isSubscriberPatient: Boolean(isSubscriberPatient),
      practiceTypeCode: practiceType,
      dateOfServiceStart,
      dateOfServiceEnd,
    };
    setRunningEligibility(true);
    const eligibilityCall = await createEligibility({ body: eligibility });
    setRunningEligibility(false);

    return (eligibilityCall as any)?.data?.data || null;
  };

  const runEligibility = async () => {
    const eligibilityResponse = await mapValuesToEligibility();

    if (isEmpty(eligibilityResponse)) {
      errorMessageSetter(`No eligibility response found. 
      Please contact the payer via phone or payer’s portal to obtain the patient's current benefit information`);
      return;
    }

    if (Object.prototype.hasOwnProperty.call(eligibilityResponse, "error")) {
      errorMessageSetter(
        `The patient coverage shows inactive, please contact the patient or the payer to obtain most current information.
        Internal Error: ${eligibilityResponse.error}`
      );
      return;
    }

    if (eligibilityResponse.planStatus === "Inactive") {
      errorMessageSetter(
        "The patient coverage shows inactive, please contact the patient or the payer to obtain most current information"
      );
      return;
    }

    if (eligibilityResponse.coinsurancePercent !== null)
      setValue(
        "coinsurancePercent",
        Number(eligibilityResponse.coinsurancePercent)
      );
    if (eligibilityResponse.flatCopay !== null)
      setValue("flatCopay", Number(eligibilityResponse.flatCopay));
    if (eligibilityResponse.remainingDeductible !== null)
      setValue("deductible", Number(eligibilityResponse.remainingDeductible));
    if (eligibilityResponse.remainingOutOfPocketMax !== null)
      setValue(
        "outOfPocketMax",
        Number(eligibilityResponse.remainingOutOfPocketMax)
      );
  };

  return (
    <div
      data-testid="footer_PayerCardFooter"
      className="flex flex-1 justify-content-end"
    >
      <Button
        label="Run Eligibility"
        onClick={runEligibility}
        loading={runningEligibility}
      />
    </div>
  );
}

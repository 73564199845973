import { LoadingSpinner } from "components/LoadingSpinner";
import { orderStatusConstants } from "constants/order";
import { events } from "constants/tagManager";
import { ToastContext } from "context/ToastContext";
import { parseDate } from "helpers/date";
import { useAnalytics } from "hooks/useAnalytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { debounce, isEmpty } from "lodash";
import { handleChangeDateWithMask } from "pages/CreateOrder/utils/mask";
import { IVariantStepProps } from "pages/OrderOverview/utils/interfaces";
import { VerificationSteps } from "pages/VerificationPage/utils/constants";
import { DOBAuthProps } from "pages/VerificationPage/utils/interfaces";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import { useGetOrderOverviewQuery } from "store/queries/order";
import { onPublicTokenChange } from "store/slices/auth";
import { onUserAccountLoad } from "store/slices/user";
import { onVerificationStepChange } from "store/slices/verification";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Card = styled.div`
  padding: ${sizer(6)};

  border: 1px solid var(--color-black-1);
  border-radius: ${sizer(2.5)};
  background-color: var(--color-white);

  max-width: ${sizer(106)};
  height: 424px;

  margin-top: ${sizer(9)};

  color: var(--text-color);

  h1 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: ${sizer(3.5)};
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: ${sizer(10)};
  }
`;

const HospitalConfirmationWrapper = styled.div`
  text-align: center;
`;

const HospitalConfirmationPatientVerification = styled.div`
  font-size: 36px;
  margin-bottom: 20px;
`;

export function DOBAuth({ variant }: DOBAuthProps) {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useDocumentTitle("Verification Page");

  const { current: toastElement } = useContext(ToastContext);
  const { pageViewEvents } = useAnalytics();

  const [value, setValue] = useState<string>("");

  const [login, { isLoading: isAuthenticationLoading }] =
    useLoginWithOrderIdAndDOBMutation();

  const { data, isError, isFetching } = useGetOrderOverviewQuery(id || "");

  const order = useMemo(() => data?.data, [data]);

  const debouncedPageViewEvents = debounce((order, event: string) => {
    pageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.communicationMethod,
      },
      event
    );
  }, 300);

  useEffect(() => {
    if (!id || isError) {
      navigate("/not-found");
    }
  }, [isError, id]);

  useEffect(() => {
    if (isEmpty(order)) return;

    if (
      order.status === orderStatusConstants.EXPIRED ||
      order.status === orderStatusConstants.CANCELED
    ) {
      navigate("/not-found");
    }

    if (
      variant === VerificationSteps.RECEIPT &&
      order.status !== orderStatusConstants.PAID
    ) {
      navigate("/not-found");
    }

    debouncedPageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.patient.preferredContactMethod,
      },
      events.VIEW_VERIFY_PAGE
    );

    dispatch(
      onUserAccountLoad({
        accountLogoUrl: order.accountLogo,
        name: order.accountName,
      })
    );
  }, [order]);

  const variantSteps: IVariantStepProps = {
    [VerificationSteps.RECEIPT]: {
      message: (
        <>
          <h1>{`${order?.patient.firstName} ${order?.patient.lastName}`},</h1>
          <p>
            Verify your identity to view the payment receipt for your upcoming
            appointment on{" "}
            {order?.dateOfService ? parseDate(order?.dateOfService, true) : ""}
          </p>
        </>
      ),
      buttonDescription: "View receipt",
      label: <Label htmlFor="dateOfBirth">Date of birth</Label>,
    },
    [VerificationSteps.HOSPITAL_CONFIRMATION]: {
      message: (
        <HospitalConfirmationWrapper>
          <HospitalConfirmationPatientVerification>
            <span>Patient Verification</span>
          </HospitalConfirmationPatientVerification>
          <p>
            {`Please verify the patient's date of birth for 
            secure access to payment details`}
          </p>
        </HospitalConfirmationWrapper>
      ),
      buttonDescription: "Verify Patient",
      label: (
        <HospitalConfirmationWrapper>
          <h1>Patient Name</h1>
          <h2>{`${order?.patient.firstName} ${order?.patient.lastName}`}</h2>
        </HospitalConfirmationWrapper>
      ),
    },
  };

  const handleOnClickToAuthenticate = () => {
    if (!id) return;

    const parsedDate = new Date(value).toISOString();

    debouncedPageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.patient.preferredContactMethod,
      },
      events.CLICK_VERIFY_PAGE
    );

    login({ orderId: id, dateOfBirth: parsedDate })
      .unwrap()
      .then(({ token }) => {
        dispatch(onPublicTokenChange({ token }));
        dispatch(onVerificationStepChange({ step: variant }));
      })
      .catch(() => {
        const detail =
          "Authentication failed. Please check your date of birth or order ID.";

        toastElement?.show({
          detail,
          summary: "Error",
          severity: "error",
        });
      });
  };

  if (isFetching || isAuthenticationLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Container>
      <Card>
        {variantSteps[variant].message}

        <div className="flex flex-column mb-3 gap-2">
          {variantSteps[variant].label}

          <InputText
            value={value}
            data-testid="dateOfBirth_DOBAuth"
            id="dateOfBirth"
            placeholder="99/99/9999"
            onChange={(e) => handleChangeDateWithMask(e, setValue)}
          />
        </div>

        <Button
          data-testid="verifyBtn_DOBAuth"
          disabled={!value}
          onClick={handleOnClickToAuthenticate}
          className="w-full justify-content-center"
        >
          {variantSteps[variant].buttonDescription}
        </Button>
      </Card>
    </Container>
  );
}

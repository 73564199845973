import { useSelector } from "react-redux";
import { orderSelector } from "store/slices/order";

import { DOBAuth } from "./components/DOBAuth";
import { Overview } from "./components/Overview";
import { Steps } from "./utils/constants";

type OrderOverviewProps = {
  variant: Steps;
};

export function OrderOverview({ variant }: OrderOverviewProps) {
  const { overviewStep } = useSelector(orderSelector);

  switch (overviewStep) {
    case Steps.ORDER_OVERVIEW:
      return <Overview />;

    default:
      return <DOBAuth {...{ variant }} />;
  }
}

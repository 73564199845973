import { getEnvironment } from "config/api";

function getMapPayerNameToCodeForDropdown(): { name: string; value: string }[] {
  const baseOptions: { name: string; value: string }[] = [
    { name: "All Other Medical", value: "128" },
    // { name: "Diagnostic Lab", value: "24" },
    { name: "MRI/CT Scan", value: "31" },
    // { name: "Diagnostic Imaging (excl. CT/MRI)", value: "32" },
    // { name: "Emergency Room", value: "54" },
  ];

  const clearHealthOption = { name: "Clear Health", value: "3" };

  const environment = getEnvironment();

  return [
    ...baseOptions,
    ...(environment === "development" ? [clearHealthOption] : []),
  ];
}

// Now, you can use the function to get the array
export const mapPayerNameToCodeForDropdown = getMapPayerNameToCodeForDropdown();

import financing from "assets/images/financing.png";
import { parsePrice } from "helpers/price";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
  width: 30vw;

  @media (max-width: 720px) {
    width: 90vw;
  }
`;

const DialogContainer = styled.div`
  h2 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: ${sizer(6.5)};
  }

  p {
    color: #383737;
    width: 70%;

    @media (max-width: 720px) {
      width: 100%;
    }
  }
`;

const StyledButtonCTA = styled(Button)`
  box-shadow: 0px 2px 15px 0px rgba(91, 77, 253, 0.46);
`;

export function FinancingModal({
  open,
  prices,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
  prices: { fullPrice: number; clearPrice: number };
}) {
  return (
    <StyledDialog
      {...{
        closable: false,
        draggable: false,
        onHide: () => {},
        visible: open,
      }}
    >
      <DialogContainer className="flex justify-content-center flex-column align-items-center">
        <img className="m-3" alt="Financing" src={financing as string} />

        <p className="mb-2 text-sm">
          1. Financing is only available for the total estimated amount{" "}
          <span className="font-bold">{parsePrice(prices.fullPrice)}</span>.
        </p>

        <p className="mb-2 text-sm">
          2. After accepting the loan, Clear Health will mark your bill as paid
          within 24h.
        </p>

        <p className="mb-2 text-sm">
          3. To get discounted Pay Now Price{" "}
          <span className="font-bold">{parsePrice(prices.clearPrice)}</span>{" "}
          complete your transaction with ClearHealth.
        </p>

        <StyledButtonCTA
          onClick={() =>
            window.open(
              "https://www.unitedcredit.com/applyforcredit?pid=0014X00002dq3S5",
              "_self"
            )
          }
          className="mt-4"
        >
          Continue to United Credit
        </StyledButtonCTA>

        <Button onClick={onClose} className="p-button-text underline mt-3 p-0">
          Cancel and pay now
        </Button>
      </DialogContainer>
    </StyledDialog>
  );
}

import { initializeApp } from "firebase/app";
import {
  Value,
  fetchAndActivate,
  getAll,
  getRemoteConfig,
} from "firebase/remote-config";
import { useState, useEffect } from "react";

import { getFirebaseConfig } from "../config/api";

const useRemoteConfig = () => {
  const [config, setConfig] = useState<Record<string, Value>>();
  const firebaseConfig = getFirebaseConfig();
  const app = initializeApp(firebaseConfig);

  const remoteConfig = getRemoteConfig(app);

  remoteConfig.settings.minimumFetchIntervalMillis = 3600;
  remoteConfig.defaultConfig = {
    gfe_feature: false,
    financing_button_overview: false,
  };

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const allConfig = getAll(remoteConfig);
        setConfig(allConfig);
      })
      .catch((err) => {
        console.error("Error fetching remote config:", err);
      });
  }, []);

  return { config };
};

export default useRemoteConfig;

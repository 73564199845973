import { LoadingSpinner } from "components/LoadingSpinner";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import useDocumentTitle from "hooks/useDocumentTitle";
import { sizer } from "layout/styles/styled/sizer";
import { Button } from "primereact/button";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteProviderMutation,
  useGetProvidersByAccountQuery,
} from "store/queries/providers";
import styled from "styled-components";
import { IProvider } from "types/Provider/Provider";

import { ProvidersTable } from "./components/ProvidersTable";

const Container = styled.div`
  display: flex;
  align-items: end;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  gap: ${sizer(2)};
`;

export function Providers() {
  const navigate = useNavigate();
  const { id: accountId } = useParams();
  const { setIsLoading } = useLoading();
  useDocumentTitle("Provider List");

  const [selectedProviders, setSelectedProviders] = useState<IProvider[]>([]);

  const [deleteMutation] = useDeleteProviderMutation();
  const { refetch, isLoading } = useGetProvidersByAccountQuery(accountId);
  const { current: toastElement } = useContext(ToastContext);

  const handleOnClickDeleteProviders = async () => {
    setIsLoading(true);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < selectedProviders.length; i++) {
      const aProvider = selectedProviders[i];
      // eslint-disable-next-line no-await-in-loop
      await deleteMutation(aProvider.id);
    }

    setSelectedProviders([]);

    refetch().then(() => {
      setIsLoading(false);

      toastElement?.show({
        severity: "success",
        summary: "Deleted!",
        detail: "Provider deleted successfully.",
      });
    });
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Container>
        <StyledButton
          icon="pi pi-plus"
          className="p-button-raised"
          onClick={() => navigate(`/providers/new?accountId=${accountId}`)}
        >
          Add new provider
        </StyledButton>
      </Container>
      <ProvidersTable
        {...{ selectedProviders }}
        onSelectChange={(event) => setSelectedProviders(event.value)}
      />
      <Container>
        <Button
          label="Delete"
          className="p-button-outlined mt-4"
          disabled={!selectedProviders.length}
          onClick={handleOnClickDeleteProviders}
        />
      </Container>
    </>
  );
}

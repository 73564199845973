import { LoadingSpinner } from "components/LoadingSpinner";
import { AccountFeeType } from "constants/fee";
import { useUserWithAccount } from "context/UserAccountProvider";
import useDocumentTitle from "hooks/useDocumentTitle";
import {
  TypesCode,
  orderTypes,
} from "pages/CreateOrder/components/Header/constants";
import { useForm, FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { IProvider, SpecialtyList } from "types/Provider/Provider";

import { specialtyDropdown } from "../../helpers/providerConstants";
import { ClearFee } from "./components/ClearFee";
import { Header } from "./components/Header";
import { HospitalDetails } from "./components/HospitalDetails";
import { OrderCommunicationDetails } from "./components/OrderCommunicationDetails";
import { PaymentAndRemit } from "./components/PaymentAndRemit";
import { SubmitEdit } from "./components/SubmitEdit";

const Space = styled.div`
  height: 20px;
`;
export function EditHospitalPage() {
  useDocumentTitle("Edit Hospital");

  const { id } = useParams();
  const { accounts, loadingAccounts } = useUserWithAccount();

  const editingAccountInfo = accounts?.data.find(
    (account: { id: string | undefined }) => account.id === id
  );

  const editingMainFacilityProvider = (
    editingAccountInfo?.providers as unknown as IProvider[]
  ).find((aProvider) => {
    return aProvider.mainFacility === true;
  });

  const patientResponsabilityCommunicationTemplate =
    editingAccountInfo?.communicationTemplates.find(
      (aCommunicationTemplate) => {
        return (
          aCommunicationTemplate.orderType === TypesCode.PatientResponsibility
        );
      }
    );

  const bundleCommunicationTemplate =
    editingAccountInfo?.communicationTemplates.find(
      (aCommunicationTemplate) => {
        return aCommunicationTemplate.orderType === TypesCode.Bundled;
      }
    );

  const erCommunicationTemplate =
    editingAccountInfo?.communicationTemplates.find(
      (aCommunicationTemplate) => {
        return aCommunicationTemplate.orderType === TypesCode.ER;
      }
    );

  const bariatricsCommunicationTemplate =
    editingAccountInfo?.communicationTemplates.find(
      (aCommunicationTemplate) => {
        return aCommunicationTemplate.orderType === TypesCode.Bariatrics;
      }
    );

  const gfeCommunicationTemplate =
    editingAccountInfo?.communicationTemplates.find(
      (aCommunicationTemplate) => {
        return aCommunicationTemplate.orderType === TypesCode.GFE;
      }
    );

  const methods = useForm({
    defaultValues: {
      hospitalName: editingAccountInfo?.name,
      addressLine1: editingMainFacilityProvider?.streetAddress,
      addressLine2: "",
      publicContactName: editingMainFacilityProvider?.publicContactName,
      publicContactRole: editingMainFacilityProvider?.publicContactRole,
      publicContactPhone: editingMainFacilityProvider?.publicContactPhone,
      city: editingMainFacilityProvider?.city,
      state: editingMainFacilityProvider?.state,
      zipcode: editingMainFacilityProvider?.zipcode,
      rcm: editingMainFacilityProvider?.rcm,
      specialty: specialtyDropdown[SpecialtyList.FACILITY].value,
      contactName: editingMainFacilityProvider?.paymentContact.contactName,
      reportType: editingAccountInfo?.reconciliationConfig.id,
      unitHospitalNumber: editingMainFacilityProvider?.unitHospitalNumber,
      contactPhone: editingMainFacilityProvider?.paymentContact.contactPhone,
      taxId: editingMainFacilityProvider?.taxPayorIdNumber,
      contactEmail: editingMainFacilityProvider?.paymentContact.contactEmail,
      emailToReceiveReports: editingMainFacilityProvider?.emailToReceiveReports,
      expirationDays: editingMainFacilityProvider?.expirationDays,
      connectAccountId: editingAccountInfo?.stripeAccountNumber,
      messageSender: patientResponsabilityCommunicationTemplate?.smsSender,
      emailSender: patientResponsabilityCommunicationTemplate?.emailSender,
      emailToReceiveNotification:
        editingMainFacilityProvider?.emailToReceiveNotification,
      frontDeskEmployeesToReceiveDailyEmail:
        editingMainFacilityProvider?.frontDeskEmployeesToReceiveDailyEmail,
      emailTemplateAttempt1:
        patientResponsabilityCommunicationTemplate?.sendGridEmailTemplateId,
      attempt1: patientResponsabilityCommunicationTemplate?.smsTemplate,
      emailTemplateAttempt2:
        patientResponsabilityCommunicationTemplate?.sendGridEmailTemplateId2,
      attempt2: patientResponsabilityCommunicationTemplate?.smsTemplate2,
      emailTemplateAttempt3:
        patientResponsabilityCommunicationTemplate?.sendGridEmailTemplateId3,
      attempt3: patientResponsabilityCommunicationTemplate?.smsTemplate3,
      emailTemplateAttemptBundle1:
        bundleCommunicationTemplate?.sendGridEmailTemplateId,
      attemptBundle1: bundleCommunicationTemplate?.smsTemplate,
      emailTemplateAttemptBundle2:
        bundleCommunicationTemplate?.sendGridEmailTemplateId2,
      attemptBundle2: bundleCommunicationTemplate?.smsTemplate2,
      emailTemplateAttemptBundle3:
        bundleCommunicationTemplate?.sendGridEmailTemplateId3,
      attemptBundle3: bundleCommunicationTemplate?.smsTemplate3,
      emailTemplateAttemptED1: erCommunicationTemplate?.sendGridEmailTemplateId,
      attemptED1: erCommunicationTemplate?.smsTemplate,
      emailTemplateAttemptED2:
        erCommunicationTemplate?.sendGridEmailTemplateId2,
      attemptED2: erCommunicationTemplate?.smsTemplate2,
      emailTemplateAttemptED3:
        erCommunicationTemplate?.sendGridEmailTemplateId3,
      attemptED3: erCommunicationTemplate?.smsTemplate3,
      emailTemplateAttemptBariatrics1:
        bariatricsCommunicationTemplate?.sendGridEmailTemplateId,
      attemptBariatrics1: bariatricsCommunicationTemplate?.smsTemplate,
      emailTemplateAttemptBariatrics2:
        bariatricsCommunicationTemplate?.sendGridEmailTemplateId2,
      attemptBariatrics2: bariatricsCommunicationTemplate?.smsTemplate2,
      emailTemplateAttemptBariatrics3:
        bariatricsCommunicationTemplate?.sendGridEmailTemplateId3,
      attemptBariatrics3: bariatricsCommunicationTemplate?.smsTemplate3,
      emailTemplateAttemptGFE1:
        gfeCommunicationTemplate?.sendGridEmailTemplateId,
      attemptGFE1: gfeCommunicationTemplate?.smsTemplate,
      emailTemplateAttemptGFE2:
        gfeCommunicationTemplate?.sendGridEmailTemplateId2,
      attemptGFE2: gfeCommunicationTemplate?.smsTemplate2,
      emailTemplateAttemptGFE3:
        gfeCommunicationTemplate?.sendGridEmailTemplateId3,
      attemptGFE3: gfeCommunicationTemplate?.smsTemplate3,
      clearFeeForClearPackage:
        editingAccountInfo?.accountFees.find(
          (accountFee: { type: string }) =>
            accountFee.type === "ClearTransactionFee"
        )?.valueInPercentage || 0,
      clearTransactionFeeForPR:
        editingAccountInfo?.accountFees.find(
          (accountFee: { type: string }) =>
            accountFee.type === AccountFeeType.CTFForPR
        )?.valueInPercentage || 0,
      patientResponsibilityDiscount:
        editingAccountInfo?.accountFees.find(
          (accountFee: { type: string }) =>
            accountFee.type === AccountFeeType.PRDiscount
        )?.valueInPercentage || 0,
      clearFinancingFee:
        editingAccountInfo?.accountFees.find(
          (accountFee: { type: string }) =>
            accountFee.type === AccountFeeType.ClearFinancingFee
        )?.valueInPercentage || 0,
      orderType: orderTypes[TypesCode.Bundled].value,
      ptFeeMethod: null,
      uploadImage: null,
      clearHealthFeeForGFE:
        editingAccountInfo?.accountFees.find(
          (accountFee: { type: string }) =>
            accountFee.type === AccountFeeType.ClearHealthFeeForGFE
        )?.valueInPercentage || 0,
      clearHealthDiscountForGFE:
        editingAccountInfo?.accountFees.find(
          (accountFee: { type: string }) =>
            accountFee.type === AccountFeeType.ClearHealthDiscountForGFE
        )?.valueInPercentage || 0,
      eligibilityEnabled: editingMainFacilityProvider?.eligibilityEnabled,
      npiNumber: editingMainFacilityProvider?.npi,
    },
  });

  if (loadingAccounts) {
    return <LoadingSpinner />;
  }

  return (
    <FormProvider {...methods}>
      <Header isEditing accountName={editingAccountInfo?.name} />
      <HospitalDetails />
      <Space />
      <PaymentAndRemit />
      <Space />
      <OrderCommunicationDetails />
      <Space />
      <ClearFee />
      {editingAccountInfo && <SubmitEdit id={editingAccountInfo.id} />}
    </FormProvider>
  );
}

import { Text, View } from "@react-pdf/renderer";
import { centsToDollars, parsePrice } from "helpers/price";
import { Pricing } from "types/CreateOrder";

import { styles } from "../styles";

export type Props = {
  gfeQuote: Pricing | undefined;
  clearPriceToPay: number;
  insuranceAmounts: any;
  isGFE: boolean;
  providerFee: number | undefined;
  facilityFee: number;
  clearDiscount: string;
};

export function ChargeDescriptionClearEstimate({
  gfeQuote,
  clearPriceToPay,
  insuranceAmounts,
  isGFE,
  providerFee,
  facilityFee,
  clearDiscount,
}: Props) {
  const {
    totalDeductibleInCents,
    totalCoinsuranceInCents,
    totalInsurancePayInCents,
    totalAllowedInsuranceAmount,
  } = insuranceAmounts;

  const totalPatientPays = gfeQuote?.pricingBreakdown.totalInCents || 0;

  return (
    <View style={{ marginLeft: 50, marginRight: 50 }}>
      <View
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <View style={styles.table}>
          <View style={[styles.row, styles.headerRow]}>
            <Text style={(styles.cell, styles.leftCell)}>
              CHARGE DESCRIPTIONS AMOUNT
            </Text>
            <Text style={(styles.cell, styles.rightCell)}>AMOUNT</Text>
          </View>
          {isGFE ? (
            <>
              <View style={[styles.row, styles.grayBackground]}>
                <Text style={[styles.cell, styles.leftCell]}>
                  Total insurance allowed amount
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(totalAllowedInsuranceAmount))}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, styles.leftCell]}>
                  Your insurance will pay for this service
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(totalInsurancePayInCents))}
                </Text>
              </View>
              <View style={[styles.row, styles.grayBackground]}>
                <Text style={[styles.cell, styles.leftCell]}>
                  Your deductible amount for this service
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(totalDeductibleInCents))}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, styles.leftCell]}>
                  Your co-insurance amount for this service
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(totalCoinsuranceInCents))}
                </Text>
              </View>
              <View style={{ ...styles.row, backgroundColor: "#a5c9eb" }}>
                <Text style={[styles.cell, styles.leftCell]}>
                  Your total estimated payment due
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(totalPatientPays))}
                </Text>
              </View>
              <View
                style={{
                  ...styles.row,
                  backgroundColor: "#426393",
                  color: "#fff",
                  fontSize: 12,
                  fontFamily: "Helvetica-Bold",
                  fontWeight: 900,
                }}
              >
                <Text style={[styles.cell, styles.leftCell]}>
                  {`Prompt pay price (${clearDiscount}% discount)`}
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(clearPriceToPay))}
                </Text>
              </View>
            </>
          ) : (
            <>
              <View style={[styles.row, styles.grayBackground]}>
                <Text style={[styles.cell, styles.leftCell]}>
                  Facility Amount
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(facilityFee))}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={[styles.cell, styles.leftCell]}>
                  Provider Amount
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(providerFee || 0))}
                </Text>
              </View>
              <View style={[styles.row, styles.grayBackground]}>
                <Text style={[styles.cell, styles.leftCell]}>
                  Clear Health Fee
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(
                    centsToDollars(
                      clearPriceToPay - (facilityFee + (providerFee || 0))
                    )
                  )}
                </Text>
              </View>
              <View
                style={{
                  ...styles.row,
                  backgroundColor: "#426393",
                  color: "#fff",
                  fontSize: 12,
                  fontFamily: "Helvetica-Bold",
                  fontWeight: 900,
                }}
              >
                <Text style={[styles.cell, styles.leftCell]}>
                  Your total estimated payment due
                </Text>
                <Text
                  style={[
                    styles.cell,
                    styles.rightCell,
                    styles.rightCellBorder,
                  ]}
                >
                  {parsePrice(centsToDollars(clearPriceToPay))}
                </Text>
              </View>
            </>
          )}
        </View>
      </View>
    </View>
  );
}

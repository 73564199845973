import { LoadingSpinner } from "components/LoadingSpinner";
import { AccountFeeType } from "constants/fee";
import { validationConstants } from "constants/formValidation";
import {
  calculateDiscount,
  centsToDollars,
  dollarsToCents,
} from "helpers/price";
import useFormValidation from "hooks/useFormValidation";
import { useGetAccountDiscountAndFee } from "hooks/useGetAccountDiscountAndFee";
import usePrice from "hooks/usePrice";
import { useProviderProcedure } from "hooks/useProviderProcedure";
import { useQuery } from "hooks/useQuery";
import { sizer } from "layout/styles/styled/sizer";
import { isEmpty } from "lodash";
import { StyledCard } from "pages/CreateOrder/CreateOrder.styled";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { Message } from "primereact/message";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  setPriceBreakdownRows,
  setRows,
  IPriceBreakdownRows,
} from "store/slices/serviceDetails";
import styled from "styled-components";
import { SpecialtyList, SpecialtyListDict } from "types/Provider/Provider";

import { ProcedureRow } from "../../../../../../types/CreateOrder";
import {
  buildGFETotalAmount,
  buildFirstColumnWithQuantityTemplate,
  buildSelectedProceduresPriceBreakdownToTable,
} from "../../builders";
import { AutoCompleteCPT } from "../Common/AutoCompleteCPT";
import { ProviderDropdown } from "../Common/ProviderDropdown";
import { RemoveItem } from "../Common/RemoveItem";
import { AdjustmentDetails } from "./AdjustmentDetails";
import { PatientResponsibilityDetails } from "./PatientResponsibilityDetails";

export const GFEFieldsContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: ${sizer(4)};
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 30px;
`;

export const CardHeader = styled.div``;

const StyledWarning = styled(Message)`
  background: transparent !important;
  max-width: 300px;
`;

export function GFEServiceDetails() {
  const query = useQuery();
  const dispatch = useDispatch();

  const accountId = query.get("accountId") || undefined;

  const {
    control,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { handleOnBlurField, getFormErrorMessage } = useFormValidation();

  const payerNameField: string = watch("payerName");
  const serviceDetailsField = watch("serviceDetails");
  const deductibleField: number = watch("deductible");
  const outOfPocketMaxField: number = watch("outOfPocketMax");
  const flatCopayField: number = watch("flatCopay");
  const coinsurancePercentField: number = watch("coinsurancePercent");

  const {
    getGFEPrices,
    priceBreakdown,
    pricingObject,
    insuranceAmounts,
    getInsuranceAmounts,
  } = usePrice();

  const discount = useGetAccountDiscountAndFee(
    AccountFeeType.ClearHealthDiscountForGFE
  );

  const { procedures, providerProcedures, search } = useProviderProcedure({
    payerNameField,
  });

  const {
    rows = [],
    autoCompleteValue = [],
    selectedProcedures = [],
    dataTableRows = [],
    displayAdjustments = false,
    displayPatientResponsibility = false,
    priceBreakdownRows = {} as IPriceBreakdownRows,
    loadingPriceBreakdown: priceExecuting,
  } = useSelector((state: RootState) => state.serviceDetailsSlice);

  const actionTemplate = (row: ProcedureRow) => {
    return <RemoveItem row={row} autoCompleteValue={autoCompleteValue} />;
  };

  const providerDropdownTemplate = (row: ProcedureRow) => {
    return <ProviderDropdown row={row} />;
  };

  useEffect(() => {
    if (isEmpty(rows)) getInsuranceAmounts(undefined);
    setValue(
      "serviceDetails",
      rows.map((row: ProcedureRow) => ({
        code: row.cptCode,
        specialty: row.specialty,
        amountInCents: row.amount,
        procedureId: row.procedureId,
        cptDescription: row.description,
        providerId: row.selectedProvider.id,
        providerName: row.selectedProvider.name,
        providerProcedureId: row.providerProcedureId,
      }))
    );
  }, [rows]);

  useEffect(() => {
    if (isEmpty(serviceDetailsField)) {
      setPriceBreakdownRows({ adjustments: [], patientResponsibility: [] });
      return;
    }

    getGFEPrices({
      accountId,
      serviceDetails: { procedures: serviceDetailsField },
      remainingDeductibleInDollar: deductibleField,
      outOfPocketMaxInDollar: outOfPocketMaxField,
      flatCopayInDollar: flatCopayField,
      coinsurancePercent: coinsurancePercentField,
    });
  }, [
    serviceDetailsField,
    deductibleField,
    outOfPocketMaxField,
    flatCopayField,
    coinsurancePercentField,
  ]);

  useEffect(() => {
    buildSelectedProceduresPriceBreakdownToTable({ priceBreakdown, dispatch });
  }, [priceBreakdown]);

  useEffect(() => {
    const totalAmountForGFE = buildGFETotalAmount(
      priceBreakdownRows.patientResponsibility
    );
    setValue("amount", centsToDollars(totalAmountForGFE));
    setValue(
      "totalInsurancePortion",
      centsToDollars(insuranceAmounts.totalInsurancePayInCents)
    );
    setValue(
      "totalInsuranceAllowable",
      centsToDollars(insuranceAmounts.totalAllowedInsuranceAmount)
    );

    setValue(
      "totalAmount",
      centsToDollars(calculateDiscount(totalAmountForGFE, discount))
    );
  }, [priceBreakdownRows]);

  useEffect(() => {
    setValue("gfeQuoteId", pricingObject?.id);
  }, [pricingObject]);

  const onAmountChange = (value: number | null, row: ProcedureRow) => {
    const convertedValue = value ? dollarsToCents(value) : 0;
    const rowIndex = rows.findIndex(
      (r) => r.id === row.id && r.procedureId === row.procedureId
    );

    if (rowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[rowIndex] = {
        ...updatedRows[rowIndex],
        amount: convertedValue,
      };

      dispatch(setRows(updatedRows));
    }
  };

  const editableAmountTemplate = (row: any) => {
    return (
      <div className="flex gap-2">
        <InputNumber
          currency="USD"
          locale="en-US"
          mode="currency"
          min={0}
          value={centsToDollars(row.amount)}
          onValueChange={({ value }) => onAmountChange(value, row)}
        />
        {row.toBeEdited && (
          <StyledWarning
            severity="warn"
            text="Please insert the correct price before saving"
          />
        )}
      </div>
    );
  };

  return (
    <>
      <StyledCard title="Service" data-testid="serviceDetails">
        <div className="formgrid grid w-100">
          <div className="field p-fluid col-12">
            <AutoCompleteCPT
              search={search}
              autoCompleteValue={autoCompleteValue}
              procedures={procedures}
              providerProcedures={providerProcedures}
            />
          </div>
        </div>

        <DataTable
          stripedRows
          value={dataTableRows}
          groupRowsBy="cptCode"
          rowGroupMode="rowspan"
          responsiveLayout="scroll"
          emptyMessage="No CPT codes selected."
          style={{
            marginLeft: sizer(-4),
            marginRight: sizer(-4),
          }}
        >
          <Column
            field="cptCode"
            header="CPT Code/Name"
            body={(row) =>
              buildFirstColumnWithQuantityTemplate({
                row,
                rows,
                autoCompleteValue,
                dispatch,
                selectedProcedures,
                procedures,
                providerProcedures,
              })
            }
            style={{
              minWidth: "100px",
              maxWidth: "150px",
              paddingLeft: sizer(6),
            }}
          />
          <Column
            field="amount"
            header="Amount"
            body={(row) => editableAmountTemplate(row)}
            headerStyle={{ width: "600px" }}
          />
          <Column
            field="specialty"
            header="Specialty"
            body={(row) => SpecialtyListDict[row.specialty as SpecialtyList]}
          />
          <Column
            field="provider"
            header="Provider"
            body={(row) => providerDropdownTemplate(row)}
          />
          <Column
            style={{ maxWidth: "80px" }}
            body={(row) => actionTemplate(row)}
          />
        </DataTable>
        {priceBreakdownRows.adjustments.length > 0 && (
          <AdjustmentDetails
            displayAdjustments={displayAdjustments}
            priceBreakdownRows={priceBreakdownRows}
            dispatch={dispatch}
            priceExecuting={priceExecuting}
          />
        )}
      </StyledCard>

      <PatientResponsibilityDetails
        displayPatientResponsibility={displayPatientResponsibility}
        priceBreakdownRows={priceBreakdownRows}
        dispatch={dispatch}
        priceExecuting={priceExecuting}
      />

      {!priceExecuting ? (
        <div className="mb-3">
          <GFEFieldsContainer>
            <div className="flex">
              <label
                htmlFor="totalInsuranceAllowable"
                className="mr-2 flex align-items-center"
              >
                Total Insurance Allowable
              </label>

              <Controller
                name="totalInsuranceAllowable"
                control={control}
                render={({ field: { value } }) => (
                  <InputNumber
                    value={value}
                    currency="USD"
                    locale="en-US"
                    mode="currency"
                    inputId="totalInsuranceAllowable"
                    disabled
                  />
                )}
              />
            </div>
          </GFEFieldsContainer>
          <GFEFieldsContainer>
            <div className="flex">
              <label
                htmlFor="totalInsurancePortion"
                className="mr-2 flex align-items-center"
              >
                Total Insurance Portion
              </label>

              <Controller
                name="totalInsurancePortion"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <InputNumber
                    value={value}
                    currency="USD"
                    locale="en-US"
                    mode="currency"
                    inputId="totalInsurancePortion"
                    onValueChange={({ value }) => onChange(value)}
                    min={0}
                    disabled
                  />
                )}
              />
            </div>
          </GFEFieldsContainer>
          <GFEFieldsContainer>
            <div className="flex">
              <label htmlFor="amount" className="mr-2 flex align-items-center">
                Total Patient Estimate
              </label>

              <Controller
                name="amount"
                control={control}
                render={({ field: { onChange, value, onBlur } }) => (
                  <>
                    <InputNumber
                      value={value}
                      currency="USD"
                      locale="en-US"
                      mode="currency"
                      inputId="amount"
                      disabled
                      onValueChange={({ value }) => onChange(value)}
                      minFractionDigits={2}
                      maxFractionDigits={5}
                      min={0}
                      onBlur={() => {
                        handleOnBlurField({
                          onBlur,
                          field: "amount",
                          trigger,
                        });
                      }}
                    />
                    {getFormErrorMessage("amount", errors)}
                  </>
                )}
              />
            </div>
          </GFEFieldsContainer>
          <GFEFieldsContainer>
            <div className="flex">
              <label
                htmlFor="totalAmount"
                className="mr-2 flex align-items-center"
              >
                Clear Price for Patient
              </label>

              <Controller
                name="totalAmount"
                control={control}
                rules={{
                  required: validationConstants.SERVICE_DETAILS.GFE_CLEAR_PRICE,
                }}
                render={({ field: { onChange, value, onBlur } }) => (
                  <>
                    <InputNumber
                      value={value}
                      currency="USD"
                      locale="en-US"
                      mode="currency"
                      inputId="totalAmount"
                      onValueChange={({ value }) => onChange(value)}
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      min={0}
                      onBlur={() => {
                        handleOnBlurField({
                          onBlur,
                          field: "amount",
                          trigger,
                        });
                      }}
                    />
                    {getFormErrorMessage("totalAmount", errors)}
                  </>
                )}
              />
            </div>
          </GFEFieldsContainer>
        </div>
      ) : (
        <LoadingContainer>
          <LoadingSpinner customStyle={{ width: "60px", height: "60px" }} />
          <h3>Calculating Totals</h3>
        </LoadingContainer>
      )}
    </>
  );
}

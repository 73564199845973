import { ItemBar } from "components/ItemBar";
import { ToastContext } from "context/ToastContext";
import { useQuery } from "hooks/useQuery";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetProvidersByAccountQuery,
  useUpdateProviderMutation,
} from "store/queries/providers";

import { mapFormToRequestBody } from "../helpers/mapFormToRequestBody";

export function SubmitEdit() {
  const query = useQuery();
  const accountId = query.get("accountId") || undefined;
  const { providerId } = useParams();

  const [useUpdateProvider] = useUpdateProviderMutation();
  const { refetch } = useGetProvidersByAccountQuery(accountId);
  const { current: toastElement } = useContext(ToastContext);
  const {
    getValues,
    formState: { isValid },
  } = useFormContext();
  const navigate = useNavigate();

  const updateProvider = async () => {
    const requestBody = await mapFormToRequestBody(getValues());

    try {
      useUpdateProvider({ body: requestBody, id: providerId })
        .unwrap()
        .then(() => {
          toastElement?.show({
            severity: "success",
            summary: "Provider updated",
            detail: "Provider updated successfully",
          });
          navigate(`/hospitals/view/${accountId}?tab=1`);
          refetch();
        })
        .catch(() =>
          toastElement?.show({
            severity: "error",
            summary: "Error",
            detail: "An error has occurred attempting to update the provider",
          })
        );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ItemBar>
      <div
        data-testid="submit__Provider"
        className="flex flex-1 justify-content-end"
      >
        <Button
          label="Edit Provider"
          onClick={updateProvider}
          disabled={!isValid}
        />
      </div>
    </ItemBar>
  );
}

import { createContext } from "react";

export interface ISPAConfig {
  apiUrl: string;
  auth0: {
    url: string;
    domain: string;
    clientId: string;
    apiAudience: string;
  };
  firebaseConfig: {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
  googleAnalyticsMeasurementId: string;
  googleTagManagerPublicId: string;
  getGoogleTagManagerPublicId: string;
  stripe: {
    publishableKey: string;
  };
}

export const getAuth0Config = (): ISPAConfig["auth0"] => {
  const config = window?.config as ISPAConfig;

  if (!config) {
    throw new Error("Config not found");
  }

  return config.auth0;
};

export const getFirebaseConfig = (): ISPAConfig["firebaseConfig"] => {
  const config = window?.config as ISPAConfig;

  if (!config) {
    throw new Error("Config not found");
  }

  return config.firebaseConfig;
};

export const getAPIUrl = (): string =>
  (window?.config as ISPAConfig)?.apiUrl || "https://localhost:3000/";

export const getGoogleAnalyticsMeasurementId = (): string =>
  (window?.config as ISPAConfig)?.googleAnalyticsMeasurementId;

export const getGoogleTagManagerPublicId = (): string =>
  (window?.config as ISPAConfig)?.googleTagManagerPublicId;

export const ConfigContext: null | any = createContext(null);

export const getEnvironment = (): string => {
  const url =
    (window?.config as ISPAConfig)?.apiUrl || "https://localhost:3000/";
  const hasDevOrLocalhost = url.includes("dev") || url.includes("localhost");
  return hasDevOrLocalhost ? "development" : "production";
};

import { orderStatusConstants } from "constants/order";
import { events } from "constants/tagManager";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import useDocumentTitle from "hooks/useDocumentTitle";
import { debounce, isEmpty } from "lodash";
import { handleChangeDateWithMask } from "pages/CreateOrder/utils/mask";
import { Steps } from "pages/OrderOverview/utils/constants";
import { DOBAuthProps } from "pages/OrderOverview/utils/interfaces";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useLoginWithOrderIdAndDOBMutation } from "store/queries/auth";
import { useGetCustomsByOrderTypeAndAccountIdQuery } from "store/queries/customize";
import { useGetOrderOverviewQuery } from "store/queries/order";
import { onPublicTokenChange } from "store/slices/auth";
import { onOrderOverviewStepChange } from "store/slices/order";
import { onUserAccountLoad } from "store/slices/user";
import styled from "styled-components";

const StyledContainer = styled.div`
  width: 320px;
  p {
    color: #2a2a2a;
  }

  .gray {
    color: #717885;
  }

  .purple {
    color: #5b4dfd;
  }

  @media (max-width: 720px) {
    min-width: 300px;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StyledCard = styled.div`
  width: 220px;

  color: #2a2a2a;
  border: 1px solid #5b4dfd;
`;

export function DOBAuth({ variant }: DOBAuthProps) {
  useDocumentTitle("Authentication - Order Overview");

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pageViewEvents } = useAnalytics();
  const { current: toastElement } = useContext(ToastContext);

  const { setIsLoading } = useLoading();
  const [value, setValue] = useState<string>("");

  const { data, isError, isFetching } = useGetOrderOverviewQuery(id);
  const [login, { isLoading }] = useLoginWithOrderIdAndDOBMutation();

  const order = useMemo(() => data?.data, [data]);

  const { data: contentData, isFetching: isContentFetching } =
    useGetCustomsByOrderTypeAndAccountIdQuery(
      {
        orderType: order?.orderType || "",
        accountId: order?.accountId || "",
      },
      {
        skip: !order,
      }
    );

  const { dobPage: content } = contentData?.data || {};

  const debouncedPageViewEvents = debounce((order, event: string) => {
    pageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.communicationMethod,
      },
      event
    );
  }, 300);

  useEffect(() => {
    setIsLoading(isFetching || isLoading || isContentFetching);
  }, [isFetching, isLoading, isContentFetching]);

  useEffect(() => {
    if (!id || isError) {
      navigate("/not-found");
    }
  }, [isError, id]);

  useEffect(() => {
    if (isEmpty(order)) return;

    if (
      order.status === orderStatusConstants.EXPIRED ||
      order.status === orderStatusConstants.CANCELED
    ) {
      navigate("/not-found");
    }

    if (
      variant === Steps.ORDER_OVERVIEW &&
      order.status === orderStatusConstants.PAID
    ) {
      navigate("/not-found");
    }

    debouncedPageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.patient.preferredContactMethod,
      },
      events.VIEW_VERIFY_PAGE
    );

    dispatch(
      onUserAccountLoad({
        accountLogoUrl: order.accountLogo,
        name: order.accountName,
      })
    );
  }, [order]);

  const dateTimeOfService = dayjs.utc(order?.dateOfService);
  const formatDateTimeOfService =
    dateTimeOfService.hour() !== 0 || dateTimeOfService.minute() !== 0
      ? "MMM D, YYYY hh:mm A"
      : "MMM D, YYYY";

  const handleOnClickToAuthenticate = () => {
    if (!id) return;

    const parsedDate = new Date(value).toISOString();

    debouncedPageViewEvents(
      {
        accountName: order?.accountName,
        orderType: order?.orderType,
        communicationMethod: order?.patient.preferredContactMethod,
      },
      events.CLICK_VERIFY_PAGE
    );

    login({ orderId: id, dateOfBirth: parsedDate })
      .unwrap()
      .then(({ token }) => {
        dispatch(onPublicTokenChange({ token }));
        dispatch(onOrderOverviewStepChange({ step: variant }));
      })
      .catch(() => {
        const detail =
          "Authentication failed. Please check your date of birth or order ID.";

        toastElement?.show({
          detail,
          summary: "Error",
          severity: "error",
        });
      });
  };

  return (
    <StyledContainer className="flex flex-column align-items-center md:mt-3 mb-auto">
      <p className="text-xs font-semibold">Notice from</p>
      <h2 className="text-2xl font-semibold mb-2">{order?.accountName}</h2>
      <p className="gray text-xs font-normal mb-2">
        Regarding your appointment:
      </p>

      <StyledCard className="p-3 mb-3 flex flex-column align-items-center border-round-sm	gap-2">
        <h2 className="font-bold text-lg">
          {order?.patient.firstName} {order?.patient.lastName}
        </h2>

        <p className="font-bold text-xs">
          {dateTimeOfService.format(formatDateTimeOfService)}
        </p>
        <p className="text-xs text-center">{order?.address}</p>
      </StyledCard>

      <h2 className="purple font-2xl font-semibold mb-2 text-center">
        {content?.headline}
      </h2>

      <p className="text-sm mb-3 text-center">{content?.description}</p>

      <div className="flex flex-column mb-3 gap-1">
        <p className="text-xs font-semibold text-center">Date of Birth</p>
        <InputText
          value={value}
          id="dateOfBirth"
          placeholder="mm/dd/yyyy"
          onChange={(e) => handleChangeDateWithMask(e, setValue)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleOnClickToAuthenticate();
            }
          }}
        />
      </div>

      <Button
        onClick={handleOnClickToAuthenticate}
        className="w-full justify-content-center mb-3"
      >
        {content?.verifyButton}
      </Button>

      <p className="gray text-center font-normal text-xs">
        Thousands of patients have used Clear to reduce their medical bills and
        you can too.
      </p>
    </StyledContainer>
  );
}

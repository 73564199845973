import { debounce, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchProviderProcedureMutation } from "store/queries/providerProcedure";
import { IProviderProcedure } from "types/ProviderProcedure";

import { useQuery } from "./useQuery";

export const CLEAR_HEALTH_PAYER_NAME = "Clear Health";

export function useProviderProcedure({
  payerNameField,
}: {
  payerNameField?: string;
}) {
  const query = useQuery();
  const accountId = query.get("accountId") || "";
  const [searchInput, setSearchInput] = useState<string>("");
  const [filteredProviderProceduresData, setFilteredProviderProceduresData] =
    useState<IProviderProcedure[]>();

  const [searchMutation, isLoading] = useSearchProviderProcedureMutation();

  const debounceSearch = useCallback(
    debounce(async () => {
      if (isEmpty(payerNameField)) return;
      const response = await searchMutation({
        accountId,
        cptCode: searchInput,
        payerName: payerNameField,
      });

      setFilteredProviderProceduresData((response as any).data.data.data || []);
    }, 300),
    [accountId, searchInput, payerNameField]
  );

  const fetchFilteredProviderProceduresData = async () => {
    try {
      await debounceSearch();
    } catch (error) {
      console.error("An error occurred when filtering:", error);
    }
  };

  useEffect(() => {
    fetchFilteredProviderProceduresData();
  }, [accountId, searchInput, payerNameField]);

  const procedures = useMemo(() => {
    return (
      filteredProviderProceduresData?.map((providerProcedure) => {
        return providerProcedure.procedure || [];
      }) || []
    );
  }, [filteredProviderProceduresData, payerNameField]);

  const search = (event: { query: string }) => {
    if (event.query.trim().length) {
      if (searchInput === event.query.toLowerCase()) {
        fetchFilteredProviderProceduresData();
      }
      setSearchInput(event.query.toLowerCase());
    }
  };

  return {
    loadedProviderProcedure: !isLoading,
    procedures,
    providerProcedures: filteredProviderProceduresData || [],
    search,
  };
}

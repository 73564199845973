export const generateRulesetList = ({
  providers,
  payers,
  file,
}: {
  providers: { name: string; value: string }[];
  payers: { name: string; value: number }[];
  file: File | null;
}) => {
  const result = [] as any[];
  const providerIds = providers.map((provider) => provider.value);
  const payerIds = payers.map((payer) => payer.value);

  providerIds.forEach((providerId) => {
    payerIds.forEach((payerId) => {
      const newObj = {
        providerId,
        payerId,
        file,
      };
      result.push(newObj);
    });
  });
  return result;
};

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetProvidersByAccountQuery } from "store/queries/providers";
import { SpecialtyListDict } from "types/Provider/Provider";

type ProvidersTableProps = {
  onSelectChange: (selectedRows: any) => void;
  selectedProviders: any[];
};

export function ProvidersTable({
  onSelectChange,
  selectedProviders,
}: ProvidersTableProps) {
  const { id: accountId } = useParams();
  const { data: providers } = useGetProvidersByAccountQuery(accountId);
  const navigate = useNavigate();

  const parsedProviders = useMemo(() => {
    return providers?.data
      .filter((aProvider) => aProvider.mainFacility === false)
      .map((aProvider) => ({
        ...aProvider,
        numberOfProceduresLive: aProvider.providerProcedures?.length || 0,
        specialty: SpecialtyListDict[aProvider.specialty],
      }));
  }, [providers?.data]);

  function providerAction(id: string) {
    return (
      <Button
        className="p-button-link"
        onClick={() => navigate(`/providers/edit/${id}?accountId=${accountId}`)}
      >
        <p className="small-text">Edit</p>
      </Button>
    );
  }

  return (
    <DataTable
      rows={10}
      rowHover
      paginator
      stripedRows
      dataKey="id"
      className="p-fluid"
      filterDisplay="row"
      value={parsedProviders || []}
      responsiveLayout="scroll"
      selection={selectedProviders}
      onSelectionChange={onSelectChange}
      emptyMessage="No providers to show."
    >
      <Column selectionMode="multiple" headerStyle={{ width: "3em" }} />
      <Column
        filter
        field="name"
        header="Provider Name"
        style={{ minWidth: "320px" }}
        filterPlaceholder="Search by provider name"
      />
      <Column
        filter
        field="specialty"
        header="Specialty"
        style={{ minWidth: "320px" }}
        filterPlaceholder="Search by specialty"
      />
      <Column
        field="numberOfProceduresLive"
        header="Number of procedures live"
        style={{ minWidth: "240px" }}
      />
      <Column
        style={{ minWidth: "200px" }}
        field="editProvider"
        body={(row) => providerAction(row.id)}
      />
    </DataTable>
  );
}

import React, { useEffect } from "react";

declare let SI_API: {
  showTab: () => void;
  showInvite: (message: string) => void;
  setChatInfo: (
    name: string,
    email: string,
    phone: string,
    department: string,
    question: string
  ) => void;
  addParams: (params: { name: string; value: string }[]) => void;
  onChatOpened: () => void;
  onChatClosed: () => void;
  onChatEnded: () => void;
};

export function ChatWidget({
  currentAccount,
  scope,
  email,
  name,
}: {
  currentAccount: string;
  scope: string;
  email: string;
  name: string;
}) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//www.socialintents.com/api/chat/socialintents.1.3.js#2c9faa359149404501914cd33fe404e1";
    script.async = true;

    window.onSIApiReady = () => {
      SI_API.showTab();

      SI_API.showInvite("Questions? I can help!");

      SI_API.setChatInfo(name || "Visitor Name", email || "", "", scope, "");

      const params = [
        { name: "Email", value: email },
        { name: "Scope", value: scope },
      ];
      SI_API.addParams(params);

      // SI_API.onChatOpened = () => {
      //   console.log("chat opened");
      // };

      // SI_API.onChatClosed = () => {
      //   console.log("chat closed");
      // };

      // SI_API.onChatEnded = () => {
      //   console.log("chat ended");
      // };
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div />;
}

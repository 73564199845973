import { LoadingSpinner } from "components/LoadingSpinner";
import { orderTypeConstants, orderTypeMapping } from "constants/order";
import useDocumentTitle from "hooks/useDocumentTitle";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAccountCustomsQuery } from "store/queries/customize";
import { IDobPage, IOverviewPage } from "types/Customize";
import { OrderType } from "types/Order/OrderType";

import { DOBForm, CheckoutForm, Submit } from "./components";
import { disclaimerDefaultValues } from "./helper";

export function CustomizePage() {
  useDocumentTitle("Customize");
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const addQueryParam = (key: string, value: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleTabChange = (e: any) => {
    setActiveTabIndex(e.index);
    addQueryParam("tab", e.index.toString());
  };

  const { data, isLoading, isFetching } = useGetAccountCustomsQuery(
    id as string
  );

  const methods = useForm();

  const customInfo = useMemo(() => {
    if (!data || !data.data) {
      return undefined;
    }

    const buildCustomInfo = data.data.reduce(
      (
        acc: { [x: string]: any },
        currentItem: { orderType: string | number }
      ) => {
        acc[currentItem.orderType] = currentItem;
        return acc;
      },
      {}
    );
    const updatedBuildCustomInfo = { ...buildCustomInfo };

    const gfe = updatedBuildCustomInfo[orderTypeConstants.GFE];
    const overviewPage = gfe?.overviewPage || {};
    if (
      !overviewPage.disclaimerTitleEnglish &&
      !overviewPage.disclaimerDescriptionEnglish &&
      !overviewPage.disclaimerTitleSpanish &&
      !overviewPage.disclaimerDescriptionSpanish
    ) {
      updatedBuildCustomInfo[orderTypeConstants.GFE] = {
        ...gfe,
        overviewPage: {
          ...overviewPage,
          ...disclaimerDefaultValues,
        },
      };
    }
    return updatedBuildCustomInfo;
  }, [data?.data]);

  const backToCustomizationList = () => {
    navigate(`/customize`);
  };

  if (!customInfo || isLoading || isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <div
        data-testid="header"
        className="flex flex-1 justify-content-end mb-3"
      >
        <Button label="Cancel" onClick={backToCustomizationList} />
      </div>
      <FormProvider {...methods}>
        <TabView
          className="w-full"
          activeIndex={activeTabIndex}
          onTabChange={handleTabChange}
        >
          <TabPanel
            header={orderTypeMapping[orderTypeConstants.PATIENT_RESPONSIBILITY]}
          >
            <DOBForm
              customFields={
                customInfo?.PatientResponsibility?.dobPage as IDobPage
              }
              orderType={orderTypeConstants.PATIENT_RESPONSIBILITY as OrderType}
            />
            <CheckoutForm
              customFields={
                customInfo?.PatientResponsibility?.overviewPage as IOverviewPage
              }
              orderType={orderTypeConstants.PATIENT_RESPONSIBILITY as OrderType}
            />
          </TabPanel>
          <TabPanel header={orderTypeMapping[orderTypeConstants.BUNDLED]}>
            <DOBForm
              customFields={customInfo?.Bundled?.dobPage as IDobPage}
              orderType={orderTypeConstants.BUNDLED as OrderType}
            />
            <CheckoutForm
              customFields={customInfo?.Bundled?.overviewPage as IOverviewPage}
              orderType={orderTypeConstants.BUNDLED as OrderType}
            />
          </TabPanel>
          <TabPanel header={orderTypeMapping[orderTypeConstants.BARIATRICS]}>
            <DOBForm
              customFields={customInfo?.Bariatrics?.dobPage as IDobPage}
              orderType={orderTypeConstants.BARIATRICS as OrderType}
            />
            <CheckoutForm
              customFields={
                customInfo?.Bariatrics?.overviewPage as IOverviewPage
              }
              orderType={orderTypeConstants.BARIATRICS as OrderType}
            />
          </TabPanel>
          <TabPanel header={orderTypeMapping[orderTypeConstants.ED]}>
            <DOBForm
              customFields={customInfo?.ED?.dobPage as IDobPage}
              orderType={orderTypeConstants.ED as OrderType}
            />
            <CheckoutForm
              customFields={customInfo?.ED?.overviewPage as IOverviewPage}
              orderType={orderTypeConstants.ED as OrderType}
            />
          </TabPanel>
          <TabPanel header={orderTypeMapping[orderTypeConstants.GFE]}>
            <DOBForm
              customFields={customInfo?.GFE?.dobPage as IDobPage}
              orderType={orderTypeConstants.GFE as OrderType}
            />
            <CheckoutForm
              customFields={customInfo?.GFE?.overviewPage as IOverviewPage}
              orderType={orderTypeConstants.GFE as OrderType}
            />
          </TabPanel>
        </TabView>
        <Submit defaultValues={customInfo} />
      </FormProvider>
    </>
  );
}

/* eslint-disable prefer-destructuring */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export function parseDate(date: string | Date, formatted?: boolean) {
  if (formatted) {
    return dayjs.utc(date).format("MMMM D, YYYY");
  }

  return dayjs.utc(date).format("MM/DD/YYYY");
}

export function parseTime(dateTime: string | Date) {
  const time = dayjs(dateTime).utc();

  if (!time.hour() && !time.minute() && !time.second()) return "-";
  return time.format("h:mm A");
}

import { validationConstants } from "constants/formValidation";
import { orderTypeConstants } from "constants/order";
import useRemoteConfig from "hooks/useRemoteConfig";
import { Label } from "layout/typography/Label";
import { Dropdown } from "primereact/dropdown";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { StyledCard } from "../../CreateOrder.styled";
import { orderTypes } from "../Header/constants";

export function OrderType() {
  const { control } = useFormContext();
  const { config } = useRemoteConfig();

  const orderTypesDropdown = useMemo(() => {
    const orderTypeValues = Object.values(orderTypes);
    if (config?.gfe_feature.asBoolean()) {
      return orderTypeValues.filter(
        (type) => type.value !== orderTypeConstants.GFE
      );
    }
    return orderTypeValues;
  }, [orderTypes]);

  return (
    <StyledCard data-testid="orderTypeTitle" title="Order Type">
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12">
            <Label htmlFor="orderType" data-testid="orderTypeLabel">
              Order Type
            </Label>

            <Controller
              data-testid="orderType"
              name="orderType"
              control={control}
              rules={{
                required: validationConstants.VISIT_INFO_FORM.DATE_OF_SERVICE,
              }}
              render={({ field: { onChange, value } }) => (
                <Dropdown
                  id="orderType"
                  data-testid="orderType"
                  value={value}
                  onChange={onChange}
                  options={orderTypesDropdown}
                  optionLabel="name"
                  placeholder="Select order type"
                  className="w-full"
                />
              )}
            />
          </div>
        </div>
      </div>
    </StyledCard>
  );
}

import { ItemBar } from "components/ItemBar";
import { events } from "constants/tagManager";
import { useLoading } from "context/LoadingContext";
import { ToastContext } from "context/ToastContext";
import { useAnalytics } from "hooks/useAnalytics";
import { Button } from "primereact/button";
import { useContext, useMemo } from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "store";
import {
  useApproveDraftOrderMutation,
  useCreateOrdersMutation,
} from "store/queries/order";

import { mapFormToRequestBody } from "../../utils/helpers";

export function Submit({ draftOrder }: { draftOrder?: boolean }) {
  const [useCreateOrders] = useCreateOrdersMutation();
  const [useApproveDraft] = useApproveDraftOrderMutation();
  const { setIsLoading } = useLoading();

  const { loadingPriceBreakdown } = useSelector(
    (state: RootState) => state.serviceDetailsSlice
  );
  const { pageViewEvents } = useAnalytics();

  const { current: toastElement } = useContext(ToastContext);
  const { getValues, watch } = useFormContext();
  const { isValid } = useFormState();
  const navigate = useNavigate();

  const procedures = watch("serviceDetails");

  const isButtonDisabled = useMemo(() => {
    return !isValid || !procedures?.length;
  }, [isValid, procedures]);

  const viewCreatedOrder = (id: string) => {
    navigate(`/orders/${id}`);
  };

  const createOrder = () => {
    setIsLoading(true);
    const requestBody = mapFormToRequestBody(getValues());

    useCreateOrders(requestBody)
      .unwrap()
      .then((createdOrder) => {
        pageViewEvents(
          {
            accountName: createdOrder.data.account.name,
            orderType: createdOrder.data.orderType,
            communicationMethod:
              createdOrder.data.patient.preferredContactMethod,
          },
          events.ORDER_CREATED
        );

        setIsLoading(false);

        toastElement?.show({
          severity: "success",
          summary: "Order created",
          detail: "Order created successfully",
          content: (
            <div className="card flex flex-wrap justify-content-center gap-3 text-center">
              <h3>Order Created!</h3>
              <p>
                Your order has been successfully created! The patient has been
                sent communication for payment collection.
              </p>
              <p>To begin collecting payment, select to view the order.</p>
              <Button
                label="View Order"
                onClick={() => viewCreatedOrder(createdOrder.data.id)}
              />
            </div>
          ),
        });
        navigate(`/hospitals/view/${createdOrder.data.account.id}?tab=0`);
      })
      .catch(() => {
        setIsLoading(false);

        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: "An error has occurred attempting to create the Order",
        });
      });
  };

  const updateOrder = () => {
    setIsLoading(true);
    const requestBody = mapFormToRequestBody(getValues());

    useApproveDraft(requestBody)
      .unwrap()
      .then((createdOrder) => {
        pageViewEvents(
          {
            accountName: createdOrder.data.account.name,
            orderType: createdOrder.data.orderType,
            communicationMethod:
              createdOrder.data.patient.preferredContactMethod,
          },
          events.ORDER_CREATED
        );

        setIsLoading(false);

        toastElement?.show({
          severity: "success",
          summary: "Order approved",
          detail: "Order approved successfully",
        });
        navigate(`/hospitals/view/${createdOrder.data.account.id}?tab=0`);
      })
      .catch(() => {
        setIsLoading(false);

        toastElement?.show({
          severity: "error",
          summary: "Error",
          detail: "An error has occurred attempting to create the Order",
        });
      });
  };

  return (
    <ItemBar>
      <div data-testid="submit" className="flex flex-1 justify-content-end">
        <Button
          label="Create Order"
          onClick={draftOrder ? updateOrder : createOrder}
          disabled={isButtonDisabled}
          loading={loadingPriceBreakdown}
        />
      </div>
    </ItemBar>
  );
}

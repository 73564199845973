import { ITransfer } from "types/MoneyTransfers";

export const mapFormToRequestBody = ({
  orderId,
  moneyTransfers,
}: {
  orderId: string;
  moneyTransfers: ITransfer[];
}) => {
  return {
    orderId,
    moneyTransfers: moneyTransfers
      .filter(
        (transfer) =>
          transfer.selectedPartyToTransferFrom &&
          transfer.selectedPartyToTransferTo?.id &&
          transfer.selectedAmountToTransfer > 0
      )
      .map((transfer) => ({
        fromProviderId: transfer?.selectedPartyToTransferFrom?.id,
        toProviderId: transfer?.selectedPartyToTransferTo?.id,
        amountInCents: transfer.selectedAmountToTransfer,
      })),
  };
};

import useFormValidation from "hooks/useFormValidation";
import { sizer } from "layout/styles/styled/sizer";
import { Label } from "layout/typography/Label";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { Controller, FieldValues, useFormContext } from "react-hook-form";
import styled from "styled-components";

const StyledCard = styled(Card)<{ ordertypefield: string }>`
  margin-bottom: ${sizer(4)};
  display: ${({ ordertypefield }) =>
    ordertypefield === "GFE" ? "block" : "none"};
`;

export function PatientBenefitsForm() {
  const { watch, control, trigger } = useFormContext<FieldValues>();

  const orderTypeField = watch("orderType");
  const { handleOnBlurField } = useFormValidation();

  return (
    <StyledCard title="Patient Benefits" ordertypefield={orderTypeField}>
      <div className="flex flex-column gap-4 w-100">
        <div className="formgrid grid w-100">
          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="deductible">Remaining Deductible</Label>

            <Controller
              name="deductible"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNumber
                  value={value}
                  currency="USD"
                  locale="en-US"
                  mode="currency"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "deductible",
                      trigger,
                    })
                  }
                  inputId="deductible"
                  onValueChange={({ value }) => onChange(value)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  min={0}
                  aria-autocomplete="none"
                />
              )}
            />
          </div>

          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="outOfPocketMax">Remaining Out-of-pocket Max</Label>

            <Controller
              name="outOfPocketMax"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNumber
                  value={value}
                  currency="USD"
                  locale="en-US"
                  mode="currency"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "outOfPocketMax",
                      trigger,
                    })
                  }
                  inputId="outOfPocketMax"
                  onValueChange={({ value }) => onChange(value)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  min={0}
                  aria-autocomplete="none"
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="flatCopay">Flat Copay</Label>

            <Controller
              name="flatCopay"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNumber
                  value={value}
                  currency="USD"
                  locale="en-US"
                  mode="currency"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "flatCopay",
                      trigger,
                    })
                  }
                  inputId="flatCopay"
                  onValueChange={({ value }) => onChange(value)}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  min={0}
                  aria-autocomplete="none"
                />
              )}
            />
          </div>
          <div className="field flex flex-column col-12 md:col-12 lg:col-3">
            <Label htmlFor="coinsurancePercent">Coinsurance Percent</Label>

            <Controller
              name="coinsurancePercent"
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <InputNumber
                  value={value}
                  suffix="%"
                  onBlur={() =>
                    handleOnBlurField({
                      onBlur,
                      field: "coinsurancePercent",
                      trigger,
                    })
                  }
                  inputId="coinsurancePercent"
                  onValueChange={({ value }) => onChange(value)}
                  min={0}
                  max={100}
                  aria-autocomplete="none"
                />
              )}
            />
          </div>
        </div>
      </div>
    </StyledCard>
  );
}

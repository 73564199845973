import { LoadingSpinner } from "components/LoadingSpinner";
import { orderStatusConstants } from "constants/order";
import { mapPayerNameToCodeForDropdown } from "constants/practiceType";
import { ToastContext } from "context/ToastContext";
import dayjs from "dayjs";
import { centsToDollars } from "helpers/price";
import useDocumentTitle from "hooks/useDocumentTitle";
import { usePayerInformation } from "hooks/usePayerInformation";
import { useQuery } from "hooks/useQuery";
import { isEmpty } from "lodash";
import { useContext, useEffect, useMemo } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "store";
import { useGetOrderQuery } from "store/queries/order";
import { onSelectDraftOrder } from "store/slices/order";
import {
  setAutoCompleteValue,
  setRows,
  setSelectedProcedures,
} from "store/slices/serviceDetails";
import { IGFEServiceDetails, IOrder } from "types/Order/Order";

import {
  VisitForm,
  Submit,
  PatientInfoForm,
  OrderType,
  ServiceDetails,
  PayerForm,
  PatientBenefitsForm,
} from "./components";
import { DraftHeader } from "./components/DraftHeader";
import { ErrorBanner } from "./components/ErrorBanner";
import { SaveDraft } from "./components/SaveDraft";
import {
  buildSelectedProceduresArray,
  buildSelectedProceduresObject,
  buildSelectedProceduresToTable,
} from "./components/ServiceDetails/builders";
import { formatPhoneNumberToMask } from "./utils/mask";

function ReviewDraftForm({ draftOrder }: { draftOrder: IOrder }) {
  const dispatch = useDispatch();
  const {
    serviceDetails,
    patient,
    procedures,
    providerProcedures,
    amountInCents,
    accountNumber,
    dateOfService,
    orderType,
    accountId,
    gfeQuote,
    gfeQuoteId,
    id,
  } = draftOrder;

  const { integrationErrors } = useSelector(
    (state: RootState) => state.orderSlice
  );

  const { payerNameOptions } = usePayerInformation();

  const initialize = () => {
    const autoCompleteValue = [
      ...new Set(
        serviceDetails.procedures.map(
          (procedure) => `${procedure.code} - ${procedure.cptDescription}`
        )
      ),
    ];

    const selectedProcedureArray = buildSelectedProceduresArray({
      procedures: (serviceDetails as IGFEServiceDetails).procedures.map(
        (procedure) => {
          return {
            code: procedure.code,
            providerProcedureId: procedure.providerProcedureId,
            specialty: procedure.specialty,
            cptDescription: procedure.cptDescription,
          };
        }
      ),
    });

    const rowsToSet = buildSelectedProceduresToTable({
      selectedProceduresObject: buildSelectedProceduresObject(
        selectedProcedureArray
      ),
      procedures: procedures || [],
      providerProcedures: providerProcedures || [],
      rows: [],
    });

    dispatch(setRows(rowsToSet));
    dispatch(setAutoCompleteValue(autoCompleteValue));
    dispatch(setSelectedProcedures(selectedProcedureArray));
  };

  const payerName = payerNameOptions.find(
    (aOption: { name: string; value: string }) =>
      aOption.name.toLowerCase() ===
      (serviceDetails as IGFEServiceDetails).payerName.toLowerCase()
  );

  const methods = useForm({
    defaultValues: {
      email: patient.email,
      amount: centsToDollars(amountInCents || 0),
      lastName: patient.lastName,
      firstName: patient.firstName,
      dateOfBirth: dayjs(patient.dateOfBirth).format("MM/DD/YYYY"),
      totalAmount: centsToDollars(
        (serviceDetails as IGFEServiceDetails)
          .patientResponsibilityEstimateAmountInCents || 0
      ),
      phoneNumber: formatPhoneNumberToMask(patient.phoneNumber),
      accountNumber,
      dateOfService: dayjs(dateOfService).format("MM/DD/YYYY"),
      timeOfService: dayjs(dateOfService).format("hh:mm A"),
      serviceDetails: serviceDetails.procedures,
      preferredContactMethod: patient.preferredContactMethod,
      estimatedVisitCostForPatient: centsToDollars(
        (serviceDetails as IGFEServiceDetails)
          .patientResponsibilityEstimateAmountInCents || 0
      ),
      orderType,
      accountId,
      payerName: payerName?.name,
      deductible: centsToDollars(gfeQuote?.remainingDeductibleInCents || 0),
      outOfPocketMax: centsToDollars(gfeQuote?.outOfPocketMaxInCents || 0),
      flatCopay: gfeQuote?.flatCopayInCents || 0,
      coinsurancePercent: gfeQuote?.coinsurancePercent || 0,
      gfeQuoteId,
      practiceType: mapPayerNameToCodeForDropdown[0]?.value,
      outOfPocketReason: null,
      orderId: id,
      subscriberFirstName: patient.firstName,
      subscriberLastName: patient.lastName,
      subscriberDOB: dayjs(patient.dateOfBirth).format("MM/DD/YYYY"),
      memberId: patient.patientBenefits.memberID,
      isSubscriberPatient: patient.patientBenefits.isSubscriberPatient,
    },
  });

  const integrationErrorsParsed = useMemo(() => {
    return integrationErrors
      .filter((error) => error.active)
      .reduce((acc, error) => {
        acc[error.errorType] = error.message;
        return acc;
      }, {} as { [key: string]: string });
  }, [integrationErrors]);

  useEffect(() => {
    initialize();
  }, []);

  return (
    <FormProvider {...methods}>
      {!isEmpty(integrationErrorsParsed) && (
        <ErrorBanner integrationErrors={integrationErrorsParsed} />
      )}
      <DraftHeader orderId={id} />
      <OrderType />
      <PatientInfoForm />
      <PayerForm />
      <PatientBenefitsForm />
      <ServiceDetails />
      <VisitForm />
      <div className="flex flex-row">
        <SaveDraft />
        <Submit draftOrder />
      </div>
    </FormProvider>
  );
}

export function ReviewOrder() {
  useDocumentTitle("Review Order");
  const { draftId } = useParams();
  const query = useQuery();
  const accountId = query.get("accountId") || "";

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: order, error } = useGetOrderQuery(draftId);

  const { current: toastElement } = useContext(ToastContext);

  const draftOrder = useMemo(() => {
    return order?.data as IOrder;
  }, [order]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!isEmpty(draftOrder)) {
      if (
        (error as any)?.originalStatus === 404 ||
        draftOrder.status !== orderStatusConstants.DRAFT
      ) {
        toastElement?.show({
          summary: "Error",
          severity: "error",
          detail: "Draft order not found.",
        });
        navigate(`/hospitals/view/${accountId}`);
      } else {
        dispatch(onSelectDraftOrder(draftOrder as IOrder));
      }
    }
  }, [draftOrder]);

  if (isEmpty(draftOrder)) {
    return <LoadingSpinner />;
  }

  return <ReviewDraftForm draftOrder={draftOrder} />;
}
